import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MapBox, { Layer, MapRef, Marker, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef, useState } from "react";
import { LocationOn, RemoveRedEye } from "@mui/icons-material";
import { useQuery } from "react-query";
import {
  createBandMapPoint,
  deleteBandMapPoint,
  getBandMapPointsForBand,
  updateBandMapPoint,
} from "./backend";
import { useParams } from "react-router-dom";
import { round } from "src/utils/numbers";
import { getKmAndMilesOutput, getLatitudeDistance } from "src/utils/distance";
import { ulid } from "ulid";
import { DndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import SortableMapPoint from "./components/SortableMapPoint";

type Coords = {
  lat: number;
  lng: number;
};

type MarkerCoords = {
  id?: string;
  name?: string;
  radius?: number;
  order?: number;
} & Coords;

const MARKER_LENGTH = 10;

const useBandMapPoints = (bandId?: string) => {
  return useQuery(["band", bandId, "map-points"], async () => {
    if (!bandId) {
      return null;
    }
    const points = await getBandMapPointsForBand(bandId);
    const sorted = points.sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      if (a?.order === undefined || b?.order === undefined) {
        return 0;
      }
      if (a.order === b.order) {
        return a?.id?.localeCompare(b?.id ?? "") ?? 0;
      }
      return Number(a?.order) - Number(b.order);
    });
    return sorted;
  });
};

export const BandMap = () => {
  const { id: bandId } = useParams<{ id: string }>();
  const [markers, setMarkers] = useState<MarkerCoords[]>([]);
  const [zoom, setZoom] = useState(10);
  const ids = useRef(new Array(MARKER_LENGTH).fill(0).map((_, i) => i + 1));
  const [viewState, setViewState] = useState<Coords | null>(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(true);
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [editingMarker, setEditingMarker] = useState<MarkerCoords | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isResorting, setIsResorting] = useState(false);
  const {
    data: bandMapPoints,
    isLoading: isLoadingBandMapPoints,
    refetch: refetchBandMapPoints,
  } = useBandMapPoints(bandId);

  const newPointRef = useRef<HTMLDivElement | null>(null);

  const mapRef = useRef<MapRef | null>(null);

  const [newMarkerName, setNewMarkerName] = useState("");

  const [newMarkerRadius, setNewMarkerRadius] = useState<number | null>(null);

  useEffect(() => {
    if (navigator.geolocation) {
      setIsLoadingLocation(true);
      navigator.geolocation.getCurrentPosition((position) => {
        setIsLoadingLocation(false);
        setViewState({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Enter" || e.key === "Escape") {
        setIsEditing(null);
        setEditingMarker(null);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    if (!bandMapPoints) {
      return;
    }
    const markers = bandMapPoints
      .map((point, idx) => {
        if (!point.id || !point.latitude || !point.longitude) {
          return null;
        }
        return {
          id: point.id,
          name: point.name || undefined,
          lat: point.latitude,
          lng: point.longitude,
          radius: point.radius ?? 5000,
          order: point.order || idx + 1,
        };
      })
      .filter(Boolean);
    setMarkers(markers as MarkerCoords[]);
  }, [bandMapPoints]);

  useEffect(() => {
    setNewMarkerName(editingMarker?.name || "");
    setNewMarkerRadius(editingMarker?.radius || 5000);
  }, [editingMarker]);

  const updateMarker = async (marker: MarkerCoords) => {
    if (!marker.id) {
      return;
    }
    const idx = markers.findIndex((m) => m.id === marker.id);
    const updatedMarker = await updateBandMapPoint({
      id: marker.id,
      name: marker.name,
      lat: marker.lat,
      lng: marker.lng,
      radius: marker.radius ?? 5000,
      order: marker.order ?? idx + 1,
    });
    if (!updatedMarker?.id) {
      setError("Error updating marker");
      return;
    }
    refetchBandMapPoints();
  };

  const handleSaveMarker = async () => {
    if (!editingMarker) {
      console.log("no editing marker");
      return;
    }
    if (editingMarker.id) {
      const idx = markers.findIndex((m) => m.id === editingMarker.id);
      const updatedMarker = await updateBandMapPoint({
        id: editingMarker.id,
        name: newMarkerName,
        lat: editingMarker.lat,
        lng: editingMarker.lng,
        radius: newMarkerRadius ?? 5000,
        order: editingMarker.order ?? idx + 1,
      });
      if (!updatedMarker?.id) {
        setError("Error updating marker");
        return;
      }
      refetchBandMapPoints();
    } else {
      if (!bandId) {
        console.log("no band id");
        return;
      }
      try {
        const newMarker = await createBandMapPoint({
          id: ulid(),
          bandId,
          name: newMarkerName,
          lat: editingMarker.lat,
          lng: editingMarker.lng,
          radius: newMarkerRadius ?? 5000,
          order: markers.length + 1,
        });
        if (!newMarker?.id) {
          throw new Error("No id returned");
        }
        refetchBandMapPoints();
      } catch (e) {
        console.log("[ERROR] error creating band map point", e);
        setMarkers(markers.filter((m) => Boolean(m.id)));
      }
    }
    setIsEditing(null);
    setEditingMarker(null);
  };
  const handleAddMarker = ({ lat, lng }: { lat: number; lng: number }) => {
    const newMarker = { lat, lng };
    setEditingMarker(newMarker);
    setTimeout(() => {
      newPointRef.current?.focus();
    }, 100);
    setMarkers([...markers, newMarker]);
  };

  const handleRemoveMarker = async (marker?: MarkerCoords | null) => {
    if (!marker || !marker.id) {
      return;
    }
    const result = await deleteBandMapPoint(marker.id);
    if (!result) {
      return;
    }
    setMarkers(markers.filter((m) => marker.id !== m.id));
    setEditingMarker(null);
    setIsEditing(null);
    refetchBandMapPoints();
  };

  const handleResort = async (resorted: MarkerCoords[]) => {
    try {
      setIsResorting(true);
      setMarkers(resorted);
      const updated = await Promise.all(
        resorted.map((marker, idx) => {
          if (!marker.id) {
            return null;
          }
          return updateBandMapPoint({
            id: marker.id,
            name: marker.name,
            lat: marker.lat,
            lng: marker.lng,
            radius: marker.radius ?? 5000,
            order: idx + 1,
          });
        }),
      );
      if (updated.some((u) => !u)) {
        setError("Error updating markers");
        return;
      }

      refetchBandMapPoints();
    } catch (e) {
      console.log("[ERROR] error updating markers", e);
    } finally {
      setIsResorting(false);
    }
  };

  const getMarkerRadius = (marker: MarkerCoords) => {
    if (!mapRef.current) {
      return 0;
    }
    const point = mapRef.current.project([marker.lng, marker.lat]);
    const point2 = mapRef.current.project([
      getLatitudeDistance({
        latitude: marker.lng,
        meters: marker.radius ?? 5000,
      }),
      getLatitudeDistance({
        latitude: marker.lng,
        meters: marker.radius ?? 5000,
      }),
    ]);
    return Math.abs(point.x - point2.x);
  };
  if (isLoadingBandMapPoints) {
    return (
      <Container maxWidth="lg" sx={{ mt: 3 }}>
        <CircularProgress />
      </Container>
    );
  }
  return (
    <Container maxWidth="lg" sx={{ my: 3 }}>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoadingLocation && <CircularProgress />}
      {viewState && (
        <>
          <MapBox
            initialViewState={{
              longitude: viewState.lng,
              latitude: viewState.lat,
              zoom,
            }}
            ref={mapRef}
            doubleClickZoom={false}
            onMove={({ viewState }) => {
              setZoom(viewState.zoom);
              setViewState({
                lat: viewState.latitude,
                lng: viewState.longitude,
              });
            }}
            latitude={viewState.lat}
            longitude={viewState.lng}
            maxZoom={10}
            minZoom={6}
            onDblClick={(e) => {
              handleAddMarker(e.lngLat);
            }}
            style={{ width: "100%", height: 600 }}
            mapStyle="mapbox://styles/mapbox/streets-v9">
            {markers.map((marker, i) => (
              <Marker
                key={`marker-${i}`}
                longitude={marker.lng}
                anchor="center"
                draggable
                onDragEnd={(e) => {
                  updateMarker(marker);
                }}
                onClick={() => setEditingMarker(marker)}
                onDrag={(e) => {
                  const newMarkers = [...markers];
                  newMarkers[i].lat = e.lngLat.lat;
                  newMarkers[i].lng = e.lngLat.lng;
                  setMarkers(newMarkers);
                }}
                latitude={marker.lat}>
                <Box sx={{ position: "relative" }}>
                  <LocationOn />
                  <Box
                    sx={{
                      position: "absolute",
                      left: -40,
                      bottom: -35,
                      right: -40,
                      display: zoom > 7 ? "flex" : "none",
                      justifyContent: "center",
                    }}>
                    <Typography
                      sx={{
                        px: 1,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        backgroundColor: "white",
                        borderRadius: 10,
                      }}
                      variant="caption">
                      {`#${i + 1} ${marker.name || ""}`}
                    </Typography>
                  </Box>
                </Box>
              </Marker>
            ))}
            <Source
              id="my-data"
              type="geojson"
              data={{
                type: "FeatureCollection",
                features: markers.map((marker) => {
                  return {
                    type: "Feature",
                    properties: {
                      radius: getMarkerRadius(marker),
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [marker.lng, marker.lat],
                    },
                  };
                }),
              }}>
              <Layer
                id="point"
                type="circle"
                paint={{
                  "circle-radius": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    1,
                    ["get", "radius"], // zoom is less than 10, radius is the value of the feature's radius property
                    10,
                    ["get", "radius"], // zoom is 10 or more, radius is the value of the feature's radius property
                  ],
                  "circle-color": "rgba(150,0,0,0.5)",
                }}
              />
            </Source>
          </MapBox>
          <Alert severity="info">
            Double-click to add a new location. Single click marker to edit.
            <Typography variant="caption" component="div">
              Press "Enter" or "Escape" to close.
            </Typography>
          </Alert>
        </>
      )}
      <Box
        sx={{
          my: 2,
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}>
        <Typography variant="h6">
          My Points ({markers.length} / {MARKER_LENGTH})
        </Typography>

        {markers && markers.length > 0 && (
          <Button
            color="error"
            onClick={() => {
              ids.current = new Array(MARKER_LENGTH)
                .fill(0)
                .map((_, i) => i + 1);
              setMarkers([]);
            }}>
            Remove all
          </Button>
        )}
      </Box>
      <DndContext
        onDragEnd={(e) => {
          const { active, over } = e;
          if (!active || !over) {
            console.log("active", active, "over", over);
            return;
          }
          const overIdx = markers.findIndex((m) => m.id === over.id);
          const activeIdx = markers.findIndex((m) => m.id === active.id);
          const before = overIdx < activeIdx;
          const rest = markers.filter((m) => m.id !== active.id);
          const marker = markers.find((m) => m.id === active.id);
          if (!marker) {
            return;
          }
          const resorted = rest.reduce((acc, m) => {
            if (m.id === over.id) {
              if (before) {
                return [...acc, marker, m];
              } else {
                return [...acc, m, marker];
              }
            }
            return [...acc, m];
          }, [] as MarkerCoords[]);
          handleResort(resorted);
        }}>
        <Grid container spacing={3}>
          <SortableContext
            items={markers.map((m, i) => m.id ?? `marker-${i}`)}
            disabled={isResorting}>
            {markers.map((marker, i) => (
              <Grid item xs={12} sm={4} key={`marker-desc-${i}`}>
                <SortableMapPoint id={marker.id ?? `marker-${i}`}>
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}>
                        {isEditing !== marker.id && (
                          <Typography
                            component="div"
                            onClick={() => {
                              if (marker.id) {
                                setEditingMarker(marker);
                              }
                            }}>
                            {marker.name || "--"}
                          </Typography>
                        )}
                        {isEditing === marker.id && (
                          <TextField
                            autoFocus
                            onBlur={() => setIsEditing(null)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSaveMarker();
                              }
                            }}
                            size="small"
                            onChange={(e) => {
                              const newMarkers = [...markers];
                              newMarkers[i].name = e.target.value;
                              setMarkers(newMarkers);
                            }}
                            value={marker.name}
                            title="Marker Name"
                          />
                        )}
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption">
                          ({round(marker.lat, 6)}, {round(marker.lng, 6)})
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        startIcon={<RemoveRedEye />}
                        onClick={() => {
                          setViewState({ lat: marker.lat, lng: marker.lng });
                        }}>
                        View
                      </Button>
                      <Button
                        color="error"
                        size="small"
                        onClick={() => handleRemoveMarker(marker)}>
                        Remove
                      </Button>
                    </CardActions>
                  </Card>
                </SortableMapPoint>
              </Grid>
            ))}
          </SortableContext>
        </Grid>
      </DndContext>
      <Dialog
        open={editingMarker !== null}
        fullWidth
        onClose={handleSaveMarker}>
        <DialogTitle
          display="flex"
          flexDirection="row"
          justifyContent="space-between">
          Editing Marker {editingMarker?.name || ""}
          <Button onClick={handleSaveMarker}>Close</Button>
        </DialogTitle>
        <DialogContent>
          {editingMarker && (
            <>
              <TextField
                autoFocus
                ref={newPointRef}
                fullWidth
                placeholder="Marker name"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSaveMarker();
                  }
                }}
                onChange={(e) => {
                  setNewMarkerName(e.target.value);
                }}
                value={newMarkerName}
                title="Marker name"
              />
              <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                <TextField
                  id="radius"
                  type="number"
                  label="Radius (in meters) (max 20,000)"
                  value={newMarkerRadius ?? ""}
                  helperText={getKmAndMilesOutput(newMarkerRadius)}
                  onChange={(e) => {
                    const newValue = parseFloat(e.target.value);

                    if (isNaN(newValue)) {
                      setNewMarkerRadius(null);
                    } else {
                      const value = Math.min(20_000, Math.max(0, newValue));
                      setNewMarkerRadius(value);
                    }
                  }}
                />
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSaveMarker}>
            Done
          </Button>
          <Button
            onClick={() => handleRemoveMarker(editingMarker)}
            variant="outlined"
            color="error">
            Delete Marker
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
