export const getLatitudeDistance = ({
  latitude,
  meters,
}: {
  latitude: number;
  meters: number;
}) => {
  const pi = Math.PI;
  const earth = 6378.137;
  const m = 1 / (((2 * pi) / 360) * earth) / 1000;

  return latitude + meters * m;
};

export const getKmAndMilesOutput = (meters?: number | null) => {
  if (!meters) {
    return "";
  }

  const km = meters / 1000;
  const miles = meters / 1609.344;

  return `${km.toFixed(2)} km / ${miles.toFixed(2)} miles`;
};
