import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Song, SongStatus } from "src/API";

const SongCard = ({
  song,
  onClickEdit,
  onClickDeactivate,
  onClickActivate,
}: {
  onClickEdit: () => void;
  onClickDeactivate: () => void;
  onClickActivate: () => void;
  song: Song;
}) => {
  const isActive = song.status === SongStatus.ACTIVE;
  return (
    <Card key={`song-${song.id}`}>
      {song.songPictureUrl && (
        <CardMedia component="img" height="250" src={song.songPictureUrl} />
      )}
      <CardContent>
        {!isActive && (
          <Alert severity="warning" sx={{ mb: 1 }}>
            <Typography>Deactivated</Typography>
          </Alert>
        )}
        <Typography
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}>
          {song.name} - {song.albumName}
        </Typography>
        {song.songUrl && (
          <audio controls style={{ width: "100%" }}>
            <source src={song.songUrl} />
          </audio>
        )}
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={onClickEdit}>
          Edit
        </Button>
        {isActive && (
          <Button variant="outlined" onClick={onClickDeactivate}>
            Deactivate
          </Button>
        )}
        {!isActive && (
          <Button variant="outlined" onClick={onClickActivate}>
            Reactivate
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default SongCard;
