import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BandShow, BandShowStatus } from "src/API";
import { updateBandShow } from "../backend";

const BandShowRequests = ({
  pendingBandShows,
  refetch,
  isFetching,
}: {
  pendingBandShows: BandShow[];
  isFetching: boolean;
  refetch: () => void;
}) => {
  const navigate = useNavigate();

  const handleAccept = async (bandShowRequestId: string) => {
    try {
      const result = await updateBandShow({
        id: bandShowRequestId,
        status: BandShowStatus.ACCEPTED,
      });
      if (result) {
        refetch();
      }
    } catch (e) {
      console.log("[ERROR] error accepting band show request", e);
    }
  };
  const handleReject = async (bandShowRequestId: string) => {
    try {
      const result = await updateBandShow({
        id: bandShowRequestId,
        status: BandShowStatus.REJECTED,
      });
      if (result) {
        refetch();
      }
    } catch (e) {
      console.log("[ERROR] error rejecting band show request", e);
    }
  };

  if (isFetching) {
    return (
      <Container sx={{ my: 2 }}>
        <LinearProgress />
      </Container>
    );
  }
  return (
    <Box sx={{ borderTop: "1px #f0f0f0 solid", mt: 3, pt: 1 }}>
      <Grid container spacing={1} sx={{ my: 1 }}>
        {pendingBandShows.map((pendingBandShow, idx) => {
          const { band, show } = pendingBandShow;
          if (!show || !band) {
            return null;
          }
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={`bandrequest-${pendingBandShow.id}-${idx}`}>
              <Card>
                {show?.showPictureUrl && (
                  <CardMedia
                    sx={{ position: "relative", cursor: "pointer" }}
                    onClick={() => navigate(`/shows/${show.id}`)}>
                    <img
                      height={200}
                      width="100%"
                      style={{
                        objectFit: "cover",
                        position: "relative",
                        zIndex: 999,
                      }}
                      src={show.showPictureUrl}
                      alt={show.name}
                    />
                    {band.profilePictureUrl && (
                      <img
                        src={band.profilePictureUrl}
                        width="100%"
                        style={{
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          width: "100%",
                          height: 200,
                          position: "absolute",
                          objectFit: "cover",
                          zIndex: 9999,
                          clipPath: "polygon(100% 0, 0% 100%, 100% 100%)",
                        }}
                        alt={band.name}
                      />
                    )}
                  </CardMedia>
                )}
                <CardContent>
                  <Typography>
                    {band.name} has been invited to the show {show?.name}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    sx={{ display: "flex", flexGrow: 1 }}
                    onClick={() => navigate(`/shows/${show.id}`)}>
                    See Show
                  </Button>
                  <Divider sx={{ ml: 1 }} orientation="vertical" flexItem />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleAccept(pendingBandShow.id)}>
                    Accept
                  </Button>
                  <Button
                    onClick={() => handleReject(pendingBandShow.id)}
                    variant="contained">
                    Reject
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default BandShowRequests;
