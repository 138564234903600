import { LocalPlay } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { isBefore } from "date-fns";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Show } from "src/API";
import { Header } from "src/components/Header";
import { LocationSearch } from "src/components/LocationSearch";
import { RootState } from "src/stores";
import { useSearch } from "src/utils/search";
import { getTimeDisplay } from "src/utils/time";
import { searchShowsByLocation } from "./backend";

export const Shows = () => {
  const [result, setResult] = useState<Show[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const latLong = searchParams.get("ll");
  const loc = searchParams.get("loc");

  const { isAuthed } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const debouncedSearch = useRef(
    debounce(
      async (location: string) => {
        try {
          const shows = await searchShowsByLocation(location);
          setResult(shows);
        } catch (error) {
          console.log("[ERROR] error searching for shows", error);
        }
      },
      500,
      { leading: true },
    ),
  ).current;

  const { search, error, hasSearched, isSearching } =
    useSearch(debouncedSearch);

  useEffect(() => {
    if (latLong) {
      search(latLong);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedVenue = async ({
    lat,
    lng,
    formatted_address,
  }: {
    lat: Number;
    lng: Number;
    formatted_address: string;
  }) => {
    const ll = `${lat}, ${lng}`;
    setSearchParams({ ll, loc: formatted_address });
    search(ll);
  };

  const renderSearchResult = () => {
    if (isSearching) {
      return <CircularProgress />;
    }
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }
    if (!result || !hasSearched) {
      return null;
    }
    const sortedResult = result.sort((a: Show, b: Show) => {
      return isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1;
    });
    return (
      <Box>
        <Grid container spacing={1}>
          {sortedResult.map((show) => {
            const slicedDesc = show.description?.slice(0, 200);
            const isSliced = show.description && show.description.length > 200;
            return (
              <Grid key={`show-${show.id}`} item xs={12} sm={6} md={4}>
                <Card>
                  <CardActionArea onClick={() => navigate(`/shows/${show.id}`)}>
                    {show.showPictureUrl && (
                      <CardMedia
                        component="img"
                        height={200}
                        src={show.showPictureUrl}
                      />
                    )}
                    <CardContent>
                      <Typography fontWeight="bold">
                        {getTimeDisplay(show.date)}
                      </Typography>
                      <Typography variant="h5" sx={{ my: 1 }}>
                        {show.name}
                      </Typography>
                      <Typography variant="body2">
                        {slicedDesc}
                        {isSliced && "..."}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };
  return (
    <div>
      <Header
        title="Shows"
        right={
          isAuthed ? (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<LocalPlay />}
              onClick={() => navigate("mine")}>
              My Shows
            </Button>
          ) : null
        }
      />
      <Container maxWidth="lg">
        <Box maxWidth="md" sx={{ mx: "auto" }}>
          <LocationSearch
            sx={{ width: "100%", my: 3 }}
            allowPostalCode
            label="Search by City or Postal Code"
            defaultValue={loc}
            onLatLongSelected={handleSelectedVenue}
          />
          {renderSearchResult()}
        </Box>
      </Container>
    </div>
  );
};
