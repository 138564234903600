export const createBand = /* GraphQL */ `
  mutation CreateBand(
    $input: CreateBandInput!
    $condition: ModelBandConditionInput
  ) {
    createBand(input: $input, condition: $condition) {
      id
      name
      description
      city
      state
      zipcode
      profilePictureUrl
    }
  }
`;

export const updateBand = /* GraphQL */ `
  mutation UpdateBand(
    $input: UpdateBandInput!
    $condition: ModelBandConditionInput
  ) {
    updateBand(input: $input, condition: $condition) {
      id
      name
      description
      city
      state
      zipcode
      profilePictureUrl
    }
  }
`;

export const createBandMember = /* GraphQL */ `
  mutation CreateBandMember(
    $input: CreateBandMemberInput!
    $condition: ModelBandMemberConditionInput
  ) {
    createBandMember(input: $input, condition: $condition) {
      id
      instrument
    }
  }
`;

export const createSong = /* GraphQL */ `
  mutation CreateSong(
    $input: CreateSongInput!
    $condition: ModelSongConditionInput
  ) {
    createSong(input: $input, condition: $condition) {
      id
      name
      albumName
      durationSeconds
      songPictureUrl
      songUrl
      order
      status
      createdAt
      updatedAt
    }
  }
`;

export const updateSong = /* GraphQL */ `
  mutation UpdateSong(
    $input: UpdateSongInput!
    $condition: ModelSongConditionInput
  ) {
    updateSong(input: $input, condition: $condition) {
      id
      name
      albumName
      durationSeconds
      songPictureUrl
      songUrl
      order
      status
      createdAt
      updatedAt
    }
  }
`;

export const inviteBandMember = /* GraphQL */ `
  mutation InviteBandMember($userId: String!, $bandId: String!) {
    inviteBandMember(userId: $userId, bandId: $bandId)
  }
`;

export const createBandFan = /* GraphQL */ `
  mutation CreateBandFan(
    $input: CreateBandFanInput!
    $condition: ModelBandFanConditionInput
  ) {
    createBandFan(input: $input, condition: $condition) {
      id
      bandId
      userId
      creatorId
      createdAt
      updatedAt
    }
  }
`;

export const deleteBandFan = /* GraphQL */ `
  mutation DeleteBandFan(
    $input: DeleteBandFanInput!
    $condition: ModelBandFanConditionInput
  ) {
    deleteBandFan(input: $input, condition: $condition) {
      id
      bandId
      userId
      creatorId
      createdAt
      updatedAt
    }
  }
`;

export const updateBandMember = /* GraphQL */ `
  mutation UpdateBandMember(
    $input: UpdateBandMemberInput!
    $condition: ModelBandMemberConditionInput
  ) {
    updateBandMember(input: $input, condition: $condition) {
      id
      instrument
      bandId
      userId
      createdAt
      updatedAt
    }
  }
`;

export const createBandPost = /* GraphQL */ `
  mutation CreateBandPost(
    $input: CreateBandPostInput!
    $condition: ModelBandPostConditionInput
  ) {
    createBandPost(input: $input, condition: $condition) {
      id
      postImageUrl
      content
      bandId
      creatorId
      createdAt
      updatedAt
    }
  }
`;

export const updateBandPost = /* GraphQL */ `
  mutation UpdateBandPost(
    $input: UpdateBandPostInput!
    $condition: ModelBandPostConditionInput
  ) {
    updateBandPost(input: $input, condition: $condition) {
      id
      postImageUrl
      content
      bandId
      creatorId
      createdAt
      updatedAt
    }
  }
`;

export const deleteBandPost = /* GraphQL */ `
  mutation DeleteBandPost(
    $input: DeleteBandPostInput!
    $condition: ModelBandPostConditionInput
  ) {
    deleteBandPost(input: $input, condition: $condition) {
      id
      postImageUrl
      content
      bandId
      creatorId
      createdAt
      updatedAt
    }
  }
`;

export const createShowGoing = /* GraphQL */ `
  mutation CreateShowGoing(
    $input: CreateShowGoingInput!
    $condition: ModelShowGoingConditionInput
  ) {
    createShowGoing(input: $input, condition: $condition) {
      id
      userId
      showId
      createdAt
      updatedAt
    }
  }
`;

export const deleteShowGoing = /* GraphQL */ `
  mutation DeleteShowGoing(
    $input: DeleteShowGoingInput!
    $condition: ModelShowGoingConditionInput
  ) {
    deleteShowGoing(input: $input, condition: $condition) {
      id
      userId
      showId
      createdAt
      updatedAt
    }
  }
`;

export const createBandHighlight = /* GraphQL */ `
  mutation CreateBandHighlight(
    $input: CreateBandHighlightInput!
    $condition: ModelBandHighlightConditionInput
  ) {
    createBandHighlight(input: $input, condition: $condition) {
      id
      bandId
      createdAt
      updatedAt
    }
  }
`;

export const deleteBandHighlight = /* GraphQL */ `
  mutation DeleteBandHighlight(
    $input: DeleteBandHighlightInput!
    $condition: ModelBandHighlightConditionInput
  ) {
    deleteBandHighlight(input: $input, condition: $condition) {
      id
      bandId
      createdAt
      updatedAt
    }
  }
`;

export const createBandMapPoint = /* GraphQL */ `
  mutation CreateBandMapPoint(
    $input: CreateBandMapPointInput!
    $condition: ModelBandMapPointConditionInput
  ) {
    createBandMapPoint(input: $input, condition: $condition) {
      id
      bandId
      name
      radius
      latitude
      longitude
      order
      _geoloc {
        lat
        lng
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBandMapPoint = /* GraphQL */ `
  mutation UpdateBandMapPoint(
    $input: UpdateBandMapPointInput!
    $condition: ModelBandMapPointConditionInput
  ) {
    updateBandMapPoint(input: $input, condition: $condition) {
      id
      bandId
      name
      radius
      latitude
      longitude
      order
      _geoloc {
        lat
        lng
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBandMapPoint = /* GraphQL */ `
  mutation DeleteBandMapPoint(
    $input: DeleteBandMapPointInput!
    $condition: ModelBandMapPointConditionInput
  ) {
    deleteBandMapPoint(input: $input, condition: $condition) {
      id
      bandId
      name
      radius
      latitude
      longitude
      order
      _geoloc {
        lat
        lng
      }
      createdAt
      updatedAt
    }
  }
`;
