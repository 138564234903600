/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  userId: string,
  firstName: string,
  lastName: string,
  username: string,
  lowerUsername?: string | null,
  profilePictureUrl?: string | null,
  profileBannerUrl?: string | null,
  isFriend?: boolean | null,
  fanCount?: number | null,
  nonce?: string | null,
  secretToken?: string | null,
  preferencesId?: string | null,
  id?: string | null,
};

export type ModelUserConditionInput = {
  userId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  username?: ModelStringInput | null,
  lowerUsername?: ModelStringInput | null,
  profilePictureUrl?: ModelStringInput | null,
  profileBannerUrl?: ModelStringInput | null,
  isFriend?: ModelBooleanInput | null,
  fanCount?: ModelIntInput | null,
  nonce?: ModelStringInput | null,
  secretToken?: ModelStringInput | null,
  preferencesId?: ModelIDInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  userId: string,
  firstName: string,
  lastName: string,
  username: string,
  lowerUsername?: string | null,
  profilePictureUrl?: string | null,
  profileBannerUrl?: string | null,
  bandPostCheers?: ModelBandPostCheerConnection | null,
  bandMemberRequests?: ModelBandMemberRequestConnection | null,
  bandMembers?: ModelBandMemberConnection | null,
  bandFans?: ModelBandFanConnection | null,
  isFriend?: boolean | null,
  friendships?: ModelFriendshipConnection | null,
  sentFriendRequests?: ModelFriendshipRequestConnection | null,
  receivedFriendRequests?: ModelFriendshipRequestConnection | null,
  fanCount?: number | null,
  bandHighlights?: ModelBandHighlightConnection | null,
  showGoings?: ModelShowGoingConnection | null,
  nonce?: string | null,
  secretToken?: string | null,
  preferencesId?: string | null,
  preferences?: UserPreferences | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelBandPostCheerConnection = {
  __typename: "ModelBandPostCheerConnection",
  items:  Array<BandPostCheer | null >,
  nextToken?: string | null,
};

export type BandPostCheer = {
  __typename: "BandPostCheer",
  id: string,
  bandPostId: string,
  bandPost?: BandPost | null,
  userId: string,
  user?: User | null,
  createdAt: string,
  updatedAt: string,
};

export type BandPost = {
  __typename: "BandPost",
  id: string,
  postImageUrl?: string | null,
  postImageUrls?: Array< string | null > | null,
  content?: string | null,
  bandId: string,
  band?: Band | null,
  creatorId?: string | null,
  bandPostCheers?: ModelBandPostCheerConnection | null,
  createdAt: string,
  isCheered?: boolean | null,
  numCheers?: number | null,
  bandGroup?: string | null,
  bandPostTimelines?: ModelBandPostTimelineConnection | null,
  updatedAt: string,
  userId?: string | null,
};

export type Band = {
  __typename: "Band",
  id: string,
  name: string,
  description?: string | null,
  city?: string | null,
  state?: string | null,
  zipcode?: string | null,
  profilePictureUrl?: string | null,
  profileBannerUrl?: string | null,
  fanCount?: number | null,
  slug?: string | null,
  bandShows?: ModelBandShowConnection | null,
  status?: BandStatus | null,
  songs?: ModelSongConnection | null,
  pendingBandShows?: ModelBandShowConnection | null,
  bandPosts?: ModelBandPostConnection | null,
  bandPostTimelines?: ModelBandPostTimelineConnection | null,
  highlights?: ModelBandHighlightConnection | null,
  bandFans?: ModelBandFanConnection | null,
  bandMembers?: ModelBandMemberConnection | null,
  BandMemberRequests?: ModelBandMemberRequestConnection | null,
  hasPendingBandShow?: boolean | null,
  showTradeStatus?: ShowTradeStatus | null,
  _geoloc?: GPS | null,
  bandMapPoints?: ModelBandMapPointConnection | null,
  bandTours?: ModelBandTourConnection | null,
  bandTourPoints?: ModelBandTourPointConnection | null,
  creatorId?: string | null,
  bandGroup?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBandShowConnection = {
  __typename: "ModelBandShowConnection",
  items:  Array<BandShow | null >,
  nextToken?: string | null,
};

export type BandShow = {
  __typename: "BandShow",
  id: string,
  date: string,
  bandId: string,
  showId: string,
  status: BandShowStatus,
  band?: Band | null,
  show?: Show | null,
  bandGroup?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum BandShowStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
}


export type Show = {
  __typename: "Show",
  id: string,
  name: string,
  description?: string | null,
  date: string,
  dateTimeStamp?: number | null,
  venueName?: string | null,
  venueAddress?: string | null,
  venueCity?: string | null,
  venueState?: string | null,
  venueZipcode?: string | null,
  showPictureUrl?: string | null,
  imGoing?: boolean | null,
  goingCount?: number | null,
  bandShows?: ModelBandShowConnection | null,
  showGoings?: ModelShowGoingConnection | null,
  openingStatus?: ShowOpeningStatus | null,
  _geoloc?: GPS | null,
  venueId?: string | null,
  venue?: Venue | null,
  creatorId: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelShowGoingConnection = {
  __typename: "ModelShowGoingConnection",
  items:  Array<ShowGoing | null >,
  nextToken?: string | null,
};

export type ShowGoing = {
  __typename: "ShowGoing",
  id: string,
  userId: string,
  showId: string,
  user?: User | null,
  show?: Show | null,
  createdAt: string,
  updatedAt: string,
};

export enum ShowOpeningStatus {
  HAS_NONE = "HAS_NONE",
  HAS_ONE = "HAS_ONE",
  HAS_MULTIPLE = "HAS_MULTIPLE",
}


export type GPS = {
  __typename: "GPS",
  lat?: number | null,
  lng?: number | null,
};

export type Venue = {
  __typename: "Venue",
  id: string,
  address: string,
  city: string,
  createdAt: string,
  icon?: string | null,
  profileImageUrl?: string | null,
  foursquareId: string,
  latitude: number,
  longitude: number,
  name: string,
  state: string,
  status?: VenueStatus | null,
  updatedAt: string,
  isBlocked?: boolean | null,
  _geoloc?: GPS | null,
  shows?: ModelShowConnection | null,
};

export enum VenueStatus {
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
  CLOSED = "CLOSED",
}


export type ModelShowConnection = {
  __typename: "ModelShowConnection",
  items:  Array<Show | null >,
  nextToken?: string | null,
};

export enum BandStatus {
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
  SUSPENDED = "SUSPENDED",
}


export type ModelSongConnection = {
  __typename: "ModelSongConnection",
  items:  Array<Song | null >,
  nextToken?: string | null,
};

export type Song = {
  __typename: "Song",
  id?: string | null,
  name?: string | null,
  albumName?: string | null,
  durationSeconds?: number | null,
  songPictureUrl?: string | null,
  order?: number | null,
  songUrl?: string | null,
  status?: SongStatus | null,
  bandId?: string | null,
  band?: Band | null,
  creatorId?: string | null,
  bandGroup?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum SongStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type ModelBandPostConnection = {
  __typename: "ModelBandPostConnection",
  items:  Array<BandPost | null >,
  nextToken?: string | null,
};

export type ModelBandPostTimelineConnection = {
  __typename: "ModelBandPostTimelineConnection",
  items:  Array<BandPostTimeline | null >,
  nextToken?: string | null,
};

export type BandPostTimeline = {
  __typename: "BandPostTimeline",
  id: string,
  userId: string,
  bandId: string,
  band?: Band | null,
  bandPostId: string,
  bandPost: BandPost,
  createdAt: string,
  status: BandPostTimelineStatus,
  updatedAt: string,
};

export enum BandPostTimelineStatus {
  VISIBLE = "VISIBLE",
  HIDDEN = "HIDDEN",
  FLAGGED = "FLAGGED",
}


export type ModelBandHighlightConnection = {
  __typename: "ModelBandHighlightConnection",
  items:  Array<BandHighlight | null >,
  nextToken?: string | null,
};

export type BandHighlight = {
  __typename: "BandHighlight",
  id: string,
  bandId: string,
  band?: Band | null,
  createdById: string,
  creator?: User | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBandFanConnection = {
  __typename: "ModelBandFanConnection",
  items:  Array<BandFan | null >,
  nextToken?: string | null,
};

export type BandFan = {
  __typename: "BandFan",
  id: string,
  bandId: string,
  userId: string,
  band?: Band | null,
  user?: User | null,
  creatorId: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelBandMemberConnection = {
  __typename: "ModelBandMemberConnection",
  items:  Array<BandMember | null >,
  nextToken?: string | null,
};

export type BandMember = {
  __typename: "BandMember",
  id: string,
  instrument?: string | null,
  band?: Band | null,
  user?: User | null,
  creatorId?: string | null,
  bandId: string,
  userId: string,
  bandGroup?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBandMemberRequestConnection = {
  __typename: "ModelBandMemberRequestConnection",
  items:  Array<BandMemberRequest | null >,
  nextToken?: string | null,
};

export type BandMemberRequest = {
  __typename: "BandMemberRequest",
  id: string,
  userId?: string | null,
  bandId: string,
  band?: Band | null,
  user?: User | null,
  status: BandMemberRequestStatus,
  bandGroup?: string | null,
  createdAt: string,
  updatedAt: string,
  creatorId?: string | null,
};

export enum BandMemberRequestStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}


export enum ShowTradeStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}


export type ModelBandMapPointConnection = {
  __typename: "ModelBandMapPointConnection",
  items:  Array<BandMapPoint | null >,
  nextToken?: string | null,
};

export type BandMapPoint = {
  __typename: "BandMapPoint",
  id?: string | null,
  bandId?: string | null,
  name?: string | null,
  band?: Band | null,
  creatorId?: string | null,
  bandGroup?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  radius?: number | null,
  order?: number | null,
  dateStart?: string | null,
  dateEnd?: string | null,
  _geoloc?: GPS | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBandTourConnection = {
  __typename: "ModelBandTourConnection",
  items:  Array<BandTour | null >,
  nextToken?: string | null,
};

export type BandTour = {
  __typename: "BandTour",
  id: string,
  bandId: string,
  startDate: string,
  endDate: string,
  bandTourPoints?: ModelBandTourPointConnection | null,
  name: string,
  band?: Band | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBandTourPointConnection = {
  __typename: "ModelBandTourPointConnection",
  items:  Array<BandTourPoint | null >,
  nextToken?: string | null,
};

export type BandTourPoint = {
  __typename: "BandTourPoint",
  id: string,
  bandId: string,
  bandTourId: string,
  band?: Band | null,
  bandTour?: BandTour | null,
  latitude: number,
  longitude: number,
  _geoloc?: GPS | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelFriendshipConnection = {
  __typename: "ModelFriendshipConnection",
  items:  Array<Friendship | null >,
  nextToken?: string | null,
};

export type Friendship = {
  __typename: "Friendship",
  id: string,
  userId: string,
  friendId: string,
  isHidden?: boolean | null,
  user?: User | null,
  friend?: User | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelFriendshipRequestConnection = {
  __typename: "ModelFriendshipRequestConnection",
  items:  Array<FriendshipRequest | null >,
  nextToken?: string | null,
};

export type FriendshipRequest = {
  __typename: "FriendshipRequest",
  id: string,
  senderId: string,
  receiverId: string,
  sender?: User | null,
  receiver?: User | null,
  status: FriendshipRequestStatus,
  createdAt: string,
  updatedAt: string,
};

export enum FriendshipRequestStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}


export type UserPreferences = {
  __typename: "UserPreferences",
  id?: string | null,
  userId?: string | null,
  wantsEmailNotifications?: boolean | null,
  wantsBandRequestEmailNotif?: boolean | null,
  wantsBandFriendEmailNotif?: boolean | null,
  wantsUserFollowEmailNotif?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserInput = {
  userId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username?: string | null,
  lowerUsername?: string | null,
  profilePictureUrl?: string | null,
  profileBannerUrl?: string | null,
  isFriend?: boolean | null,
  fanCount?: number | null,
  nonce?: string | null,
  secretToken?: string | null,
  preferencesId?: string | null,
  id: string,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateUserPreferencesInput = {
  id?: string | null,
  userId?: string | null,
  wantsEmailNotifications?: boolean | null,
  wantsBandRequestEmailNotif?: boolean | null,
  wantsBandFriendEmailNotif?: boolean | null,
  wantsUserFollowEmailNotif?: boolean | null,
};

export type ModelUserPreferencesConditionInput = {
  userId?: ModelIDInput | null,
  wantsEmailNotifications?: ModelBooleanInput | null,
  wantsBandRequestEmailNotif?: ModelBooleanInput | null,
  wantsBandFriendEmailNotif?: ModelBooleanInput | null,
  wantsUserFollowEmailNotif?: ModelBooleanInput | null,
  and?: Array< ModelUserPreferencesConditionInput | null > | null,
  or?: Array< ModelUserPreferencesConditionInput | null > | null,
  not?: ModelUserPreferencesConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserPreferencesInput = {
  id: string,
  userId?: string | null,
  wantsEmailNotifications?: boolean | null,
  wantsBandRequestEmailNotif?: boolean | null,
  wantsBandFriendEmailNotif?: boolean | null,
  wantsUserFollowEmailNotif?: boolean | null,
};

export type DeleteUserPreferencesInput = {
  id: string,
};

export type CreateBandInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  city?: string | null,
  state?: string | null,
  zipcode?: string | null,
  profilePictureUrl?: string | null,
  profileBannerUrl?: string | null,
  fanCount?: number | null,
  slug?: string | null,
  status?: BandStatus | null,
  hasPendingBandShow?: boolean | null,
  showTradeStatus?: ShowTradeStatus | null,
  _geoloc?: GPSInput | null,
  creatorId?: string | null,
  bandGroup?: string | null,
};

export type GPSInput = {
  lat?: number | null,
  lng?: number | null,
};

export type ModelBandConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zipcode?: ModelStringInput | null,
  profilePictureUrl?: ModelStringInput | null,
  profileBannerUrl?: ModelStringInput | null,
  fanCount?: ModelIntInput | null,
  slug?: ModelStringInput | null,
  status?: ModelBandStatusInput | null,
  hasPendingBandShow?: ModelBooleanInput | null,
  showTradeStatus?: ModelShowTradeStatusInput | null,
  creatorId?: ModelIDInput | null,
  bandGroup?: ModelStringInput | null,
  and?: Array< ModelBandConditionInput | null > | null,
  or?: Array< ModelBandConditionInput | null > | null,
  not?: ModelBandConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBandStatusInput = {
  eq?: BandStatus | null,
  ne?: BandStatus | null,
};

export type ModelShowTradeStatusInput = {
  eq?: ShowTradeStatus | null,
  ne?: ShowTradeStatus | null,
};

export type UpdateBandInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  city?: string | null,
  state?: string | null,
  zipcode?: string | null,
  profilePictureUrl?: string | null,
  profileBannerUrl?: string | null,
  fanCount?: number | null,
  slug?: string | null,
  status?: BandStatus | null,
  hasPendingBandShow?: boolean | null,
  showTradeStatus?: ShowTradeStatus | null,
  _geoloc?: GPSInput | null,
  creatorId?: string | null,
  bandGroup?: string | null,
};

export type DeleteBandInput = {
  id: string,
};

export type CreateBandMemberInput = {
  id?: string | null,
  instrument?: string | null,
  creatorId?: string | null,
  bandId: string,
  userId: string,
  bandGroup?: string | null,
};

export type ModelBandMemberConditionInput = {
  instrument?: ModelStringInput | null,
  creatorId?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  bandGroup?: ModelStringInput | null,
  and?: Array< ModelBandMemberConditionInput | null > | null,
  or?: Array< ModelBandMemberConditionInput | null > | null,
  not?: ModelBandMemberConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateBandMemberInput = {
  id: string,
  instrument?: string | null,
  creatorId?: string | null,
  bandId?: string | null,
  userId?: string | null,
  bandGroup?: string | null,
};

export type DeleteBandMemberInput = {
  id: string,
};

export type CreateBandMemberRequestInput = {
  id?: string | null,
  userId?: string | null,
  bandId: string,
  status: BandMemberRequestStatus,
  bandGroup?: string | null,
};

export type ModelBandMemberRequestConditionInput = {
  userId?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  status?: ModelBandMemberRequestStatusInput | null,
  bandGroup?: ModelStringInput | null,
  and?: Array< ModelBandMemberRequestConditionInput | null > | null,
  or?: Array< ModelBandMemberRequestConditionInput | null > | null,
  not?: ModelBandMemberRequestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  creatorId?: ModelStringInput | null,
};

export type ModelBandMemberRequestStatusInput = {
  eq?: BandMemberRequestStatus | null,
  ne?: BandMemberRequestStatus | null,
};

export type UpdateBandMemberRequestInput = {
  id: string,
  userId?: string | null,
  bandId?: string | null,
  status?: BandMemberRequestStatus | null,
  bandGroup?: string | null,
};

export type DeleteBandMemberRequestInput = {
  id: string,
};

export type CreateBandPostInput = {
  id?: string | null,
  postImageUrl?: string | null,
  postImageUrls?: Array< string | null > | null,
  content?: string | null,
  bandId: string,
  creatorId?: string | null,
  createdAt?: string | null,
  isCheered?: boolean | null,
  numCheers?: number | null,
  bandGroup?: string | null,
};

export type ModelBandPostConditionInput = {
  postImageUrl?: ModelStringInput | null,
  postImageUrls?: ModelStringInput | null,
  content?: ModelStringInput | null,
  bandId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  isCheered?: ModelBooleanInput | null,
  numCheers?: ModelIntInput | null,
  bandGroup?: ModelStringInput | null,
  and?: Array< ModelBandPostConditionInput | null > | null,
  or?: Array< ModelBandPostConditionInput | null > | null,
  not?: ModelBandPostConditionInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelStringInput | null,
};

export type UpdateBandPostInput = {
  id: string,
  postImageUrl?: string | null,
  postImageUrls?: Array< string | null > | null,
  content?: string | null,
  bandId?: string | null,
  creatorId?: string | null,
  createdAt?: string | null,
  isCheered?: boolean | null,
  numCheers?: number | null,
  bandGroup?: string | null,
};

export type DeleteBandPostInput = {
  id: string,
};

export type CreateBandPostTimelineInput = {
  id?: string | null,
  userId: string,
  bandId: string,
  bandPostId: string,
  createdAt?: string | null,
  status: BandPostTimelineStatus,
};

export type ModelBandPostTimelineConditionInput = {
  userId?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  bandPostId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  status?: ModelBandPostTimelineStatusInput | null,
  and?: Array< ModelBandPostTimelineConditionInput | null > | null,
  or?: Array< ModelBandPostTimelineConditionInput | null > | null,
  not?: ModelBandPostTimelineConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBandPostTimelineStatusInput = {
  eq?: BandPostTimelineStatus | null,
  ne?: BandPostTimelineStatus | null,
};

export type UpdateBandPostTimelineInput = {
  id: string,
  userId?: string | null,
  bandId?: string | null,
  bandPostId?: string | null,
  createdAt?: string | null,
  status?: BandPostTimelineStatus | null,
};

export type DeleteBandPostTimelineInput = {
  id: string,
};

export type CreateBandPostCheerInput = {
  id?: string | null,
  bandPostId: string,
  userId: string,
};

export type ModelBandPostCheerConditionInput = {
  bandPostId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelBandPostCheerConditionInput | null > | null,
  or?: Array< ModelBandPostCheerConditionInput | null > | null,
  not?: ModelBandPostCheerConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateBandPostCheerInput = {
  id: string,
  bandPostId?: string | null,
  userId?: string | null,
};

export type DeleteBandPostCheerInput = {
  id: string,
};

export type CreateSongInput = {
  id?: string | null,
  name?: string | null,
  albumName?: string | null,
  durationSeconds?: number | null,
  songPictureUrl?: string | null,
  order?: number | null,
  songUrl?: string | null,
  status?: SongStatus | null,
  bandId?: string | null,
  creatorId?: string | null,
  bandGroup?: string | null,
};

export type ModelSongConditionInput = {
  name?: ModelStringInput | null,
  albumName?: ModelStringInput | null,
  durationSeconds?: ModelIntInput | null,
  songPictureUrl?: ModelStringInput | null,
  order?: ModelIntInput | null,
  songUrl?: ModelStringInput | null,
  status?: ModelSongStatusInput | null,
  bandId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  bandGroup?: ModelStringInput | null,
  and?: Array< ModelSongConditionInput | null > | null,
  or?: Array< ModelSongConditionInput | null > | null,
  not?: ModelSongConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelSongStatusInput = {
  eq?: SongStatus | null,
  ne?: SongStatus | null,
};

export type UpdateSongInput = {
  id: string,
  name?: string | null,
  albumName?: string | null,
  durationSeconds?: number | null,
  songPictureUrl?: string | null,
  order?: number | null,
  songUrl?: string | null,
  status?: SongStatus | null,
  bandId?: string | null,
  creatorId?: string | null,
  bandGroup?: string | null,
};

export type DeleteSongInput = {
  id: string,
};

export type CreateShowInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  date: string,
  dateTimeStamp?: number | null,
  venueName?: string | null,
  venueAddress?: string | null,
  venueCity?: string | null,
  venueState?: string | null,
  venueZipcode?: string | null,
  showPictureUrl?: string | null,
  imGoing?: boolean | null,
  goingCount?: number | null,
  openingStatus?: ShowOpeningStatus | null,
  _geoloc?: GPSInput | null,
  venueId?: string | null,
  creatorId: string,
};

export type ModelShowConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  date?: ModelStringInput | null,
  dateTimeStamp?: ModelFloatInput | null,
  venueName?: ModelStringInput | null,
  venueAddress?: ModelStringInput | null,
  venueCity?: ModelStringInput | null,
  venueState?: ModelStringInput | null,
  venueZipcode?: ModelStringInput | null,
  showPictureUrl?: ModelStringInput | null,
  imGoing?: ModelBooleanInput | null,
  goingCount?: ModelIntInput | null,
  openingStatus?: ModelShowOpeningStatusInput | null,
  venueId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  and?: Array< ModelShowConditionInput | null > | null,
  or?: Array< ModelShowConditionInput | null > | null,
  not?: ModelShowConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelShowOpeningStatusInput = {
  eq?: ShowOpeningStatus | null,
  ne?: ShowOpeningStatus | null,
};

export type UpdateShowInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  date?: string | null,
  dateTimeStamp?: number | null,
  venueName?: string | null,
  venueAddress?: string | null,
  venueCity?: string | null,
  venueState?: string | null,
  venueZipcode?: string | null,
  showPictureUrl?: string | null,
  imGoing?: boolean | null,
  goingCount?: number | null,
  openingStatus?: ShowOpeningStatus | null,
  _geoloc?: GPSInput | null,
  venueId?: string | null,
  creatorId?: string | null,
};

export type DeleteShowInput = {
  id: string,
};

export type CreateBandShowInput = {
  id?: string | null,
  date: string,
  bandId: string,
  showId: string,
  status: BandShowStatus,
  bandGroup?: string | null,
};

export type ModelBandShowConditionInput = {
  date?: ModelStringInput | null,
  bandId?: ModelIDInput | null,
  showId?: ModelIDInput | null,
  status?: ModelBandShowStatusInput | null,
  bandGroup?: ModelStringInput | null,
  and?: Array< ModelBandShowConditionInput | null > | null,
  or?: Array< ModelBandShowConditionInput | null > | null,
  not?: ModelBandShowConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBandShowStatusInput = {
  eq?: BandShowStatus | null,
  ne?: BandShowStatus | null,
};

export type UpdateBandShowInput = {
  id: string,
  date?: string | null,
  bandId?: string | null,
  showId?: string | null,
  status?: BandShowStatus | null,
  bandGroup?: string | null,
};

export type DeleteBandShowInput = {
  id: string,
};

export type CreateVenueInput = {
  id?: string | null,
  address: string,
  city: string,
  createdAt?: string | null,
  icon?: string | null,
  profileImageUrl?: string | null,
  foursquareId: string,
  latitude: number,
  longitude: number,
  name: string,
  state: string,
  status?: VenueStatus | null,
  updatedAt?: string | null,
  isBlocked?: boolean | null,
  _geoloc?: GPSInput | null,
};

export type ModelVenueConditionInput = {
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  profileImageUrl?: ModelStringInput | null,
  foursquareId?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  name?: ModelStringInput | null,
  state?: ModelStringInput | null,
  status?: ModelVenueStatusInput | null,
  updatedAt?: ModelStringInput | null,
  isBlocked?: ModelBooleanInput | null,
  and?: Array< ModelVenueConditionInput | null > | null,
  or?: Array< ModelVenueConditionInput | null > | null,
  not?: ModelVenueConditionInput | null,
};

export type ModelVenueStatusInput = {
  eq?: VenueStatus | null,
  ne?: VenueStatus | null,
};

export type UpdateVenueInput = {
  id: string,
  address?: string | null,
  city?: string | null,
  createdAt?: string | null,
  icon?: string | null,
  profileImageUrl?: string | null,
  foursquareId?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  name?: string | null,
  state?: string | null,
  status?: VenueStatus | null,
  updatedAt?: string | null,
  isBlocked?: boolean | null,
  _geoloc?: GPSInput | null,
};

export type DeleteVenueInput = {
  id: string,
};

export type CreateBandFanInput = {
  id?: string | null,
  bandId: string,
  userId: string,
  creatorId: string,
};

export type ModelBandFanConditionInput = {
  bandId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  and?: Array< ModelBandFanConditionInput | null > | null,
  or?: Array< ModelBandFanConditionInput | null > | null,
  not?: ModelBandFanConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateBandFanInput = {
  id: string,
  bandId?: string | null,
  userId?: string | null,
  creatorId?: string | null,
};

export type DeleteBandFanInput = {
  id: string,
};

export type CreateBandMapPointInput = {
  id?: string | null,
  bandId?: string | null,
  name?: string | null,
  creatorId?: string | null,
  bandGroup?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  radius?: number | null,
  order?: number | null,
  dateStart?: string | null,
  dateEnd?: string | null,
  _geoloc?: GPSInput | null,
};

export type ModelBandMapPointConditionInput = {
  bandId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  creatorId?: ModelIDInput | null,
  bandGroup?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  radius?: ModelFloatInput | null,
  order?: ModelIntInput | null,
  dateStart?: ModelStringInput | null,
  dateEnd?: ModelStringInput | null,
  and?: Array< ModelBandMapPointConditionInput | null > | null,
  or?: Array< ModelBandMapPointConditionInput | null > | null,
  not?: ModelBandMapPointConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateBandMapPointInput = {
  id: string,
  bandId?: string | null,
  name?: string | null,
  creatorId?: string | null,
  bandGroup?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  radius?: number | null,
  order?: number | null,
  dateStart?: string | null,
  dateEnd?: string | null,
  _geoloc?: GPSInput | null,
};

export type DeleteBandMapPointInput = {
  id: string,
};

export type CreateBandTourInput = {
  id?: string | null,
  bandId: string,
  startDate: string,
  endDate: string,
  name: string,
};

export type ModelBandTourConditionInput = {
  bandId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelBandTourConditionInput | null > | null,
  or?: Array< ModelBandTourConditionInput | null > | null,
  not?: ModelBandTourConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateBandTourInput = {
  id: string,
  bandId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  name?: string | null,
};

export type DeleteBandTourInput = {
  id: string,
};

export type CreateBandTourPointInput = {
  id?: string | null,
  bandId: string,
  bandTourId: string,
  latitude: number,
  longitude: number,
  _geoloc?: GPSInput | null,
};

export type ModelBandTourPointConditionInput = {
  bandId?: ModelIDInput | null,
  bandTourId?: ModelIDInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  and?: Array< ModelBandTourPointConditionInput | null > | null,
  or?: Array< ModelBandTourPointConditionInput | null > | null,
  not?: ModelBandTourPointConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateBandTourPointInput = {
  id: string,
  bandId?: string | null,
  bandTourId?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  _geoloc?: GPSInput | null,
};

export type DeleteBandTourPointInput = {
  id: string,
};

export type CreateShowGoingInput = {
  id?: string | null,
  userId: string,
  showId: string,
};

export type ModelShowGoingConditionInput = {
  userId?: ModelIDInput | null,
  showId?: ModelIDInput | null,
  and?: Array< ModelShowGoingConditionInput | null > | null,
  or?: Array< ModelShowGoingConditionInput | null > | null,
  not?: ModelShowGoingConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateShowGoingInput = {
  id: string,
  userId?: string | null,
  showId?: string | null,
};

export type DeleteShowGoingInput = {
  id: string,
};

export type CreateFriendshipRequestInput = {
  id?: string | null,
  senderId: string,
  receiverId: string,
  status: FriendshipRequestStatus,
};

export type ModelFriendshipRequestConditionInput = {
  senderId?: ModelIDInput | null,
  receiverId?: ModelIDInput | null,
  status?: ModelFriendshipRequestStatusInput | null,
  and?: Array< ModelFriendshipRequestConditionInput | null > | null,
  or?: Array< ModelFriendshipRequestConditionInput | null > | null,
  not?: ModelFriendshipRequestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelFriendshipRequestStatusInput = {
  eq?: FriendshipRequestStatus | null,
  ne?: FriendshipRequestStatus | null,
};

export type UpdateFriendshipRequestInput = {
  id: string,
  senderId?: string | null,
  receiverId?: string | null,
  status?: FriendshipRequestStatus | null,
};

export type DeleteFriendshipRequestInput = {
  id: string,
};

export type CreateFriendshipInput = {
  id?: string | null,
  userId: string,
  friendId: string,
  isHidden?: boolean | null,
};

export type ModelFriendshipConditionInput = {
  userId?: ModelIDInput | null,
  friendId?: ModelIDInput | null,
  isHidden?: ModelBooleanInput | null,
  and?: Array< ModelFriendshipConditionInput | null > | null,
  or?: Array< ModelFriendshipConditionInput | null > | null,
  not?: ModelFriendshipConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateFriendshipInput = {
  id: string,
  userId?: string | null,
  friendId?: string | null,
  isHidden?: boolean | null,
};

export type DeleteFriendshipInput = {
  id: string,
};

export type CreateBandHighlightInput = {
  id?: string | null,
  bandId: string,
  createdById: string,
};

export type ModelBandHighlightConditionInput = {
  bandId?: ModelIDInput | null,
  createdById?: ModelIDInput | null,
  and?: Array< ModelBandHighlightConditionInput | null > | null,
  or?: Array< ModelBandHighlightConditionInput | null > | null,
  not?: ModelBandHighlightConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateBandHighlightInput = {
  id: string,
  bandId?: string | null,
  createdById?: string | null,
};

export type DeleteBandHighlightInput = {
  id: string,
};

export enum BandMemberRequestResponse {
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
}


export type ModelUserFilterInput = {
  userId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  username?: ModelStringInput | null,
  lowerUsername?: ModelStringInput | null,
  profilePictureUrl?: ModelStringInput | null,
  profileBannerUrl?: ModelStringInput | null,
  isFriend?: ModelBooleanInput | null,
  fanCount?: ModelIntInput | null,
  nonce?: ModelStringInput | null,
  secretToken?: ModelStringInput | null,
  preferencesId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserPreferencesFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  wantsEmailNotifications?: ModelBooleanInput | null,
  wantsBandRequestEmailNotif?: ModelBooleanInput | null,
  wantsBandFriendEmailNotif?: ModelBooleanInput | null,
  wantsUserFollowEmailNotif?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserPreferencesFilterInput | null > | null,
  or?: Array< ModelUserPreferencesFilterInput | null > | null,
  not?: ModelUserPreferencesFilterInput | null,
};

export type ModelUserPreferencesConnection = {
  __typename: "ModelUserPreferencesConnection",
  items:  Array<UserPreferences | null >,
  nextToken?: string | null,
};

export type ModelBandFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zipcode?: ModelStringInput | null,
  profilePictureUrl?: ModelStringInput | null,
  profileBannerUrl?: ModelStringInput | null,
  fanCount?: ModelIntInput | null,
  slug?: ModelStringInput | null,
  status?: ModelBandStatusInput | null,
  hasPendingBandShow?: ModelBooleanInput | null,
  showTradeStatus?: ModelShowTradeStatusInput | null,
  creatorId?: ModelIDInput | null,
  bandGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandFilterInput | null > | null,
  or?: Array< ModelBandFilterInput | null > | null,
  not?: ModelBandFilterInput | null,
};

export type ModelBandConnection = {
  __typename: "ModelBandConnection",
  items:  Array<Band | null >,
  nextToken?: string | null,
};

export type ModelBandMemberFilterInput = {
  id?: ModelIDInput | null,
  instrument?: ModelStringInput | null,
  creatorId?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  bandGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandMemberFilterInput | null > | null,
  or?: Array< ModelBandMemberFilterInput | null > | null,
  not?: ModelBandMemberFilterInput | null,
};

export type ModelBandMemberRequestFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  status?: ModelBandMemberRequestStatusInput | null,
  bandGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandMemberRequestFilterInput | null > | null,
  or?: Array< ModelBandMemberRequestFilterInput | null > | null,
  not?: ModelBandMemberRequestFilterInput | null,
  creatorId?: ModelStringInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelBandPostFilterInput = {
  id?: ModelIDInput | null,
  postImageUrl?: ModelStringInput | null,
  postImageUrls?: ModelStringInput | null,
  content?: ModelStringInput | null,
  bandId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  isCheered?: ModelBooleanInput | null,
  numCheers?: ModelIntInput | null,
  bandGroup?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandPostFilterInput | null > | null,
  or?: Array< ModelBandPostFilterInput | null > | null,
  not?: ModelBandPostFilterInput | null,
  userId?: ModelStringInput | null,
};

export type ModelBandPostTimelineFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  bandPostId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  status?: ModelBandPostTimelineStatusInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandPostTimelineFilterInput | null > | null,
  or?: Array< ModelBandPostTimelineFilterInput | null > | null,
  not?: ModelBandPostTimelineFilterInput | null,
};

export type ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyConditionInput = {
  eq?: ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyInput | null,
  le?: ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyInput | null,
  lt?: ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyInput | null,
  ge?: ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyInput | null,
  gt?: ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyInput | null,
  between?: Array< ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyInput | null,
};

export type ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyInput = {
  status?: BandPostTimelineStatus | null,
  createdAt?: string | null,
};

export type ModelBandPostCheerFilterInput = {
  id?: ModelIDInput | null,
  bandPostId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandPostCheerFilterInput | null > | null,
  or?: Array< ModelBandPostCheerFilterInput | null > | null,
  not?: ModelBandPostCheerFilterInput | null,
};

export type ModelSongFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  albumName?: ModelStringInput | null,
  durationSeconds?: ModelIntInput | null,
  songPictureUrl?: ModelStringInput | null,
  order?: ModelIntInput | null,
  songUrl?: ModelStringInput | null,
  status?: ModelSongStatusInput | null,
  bandId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  bandGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSongFilterInput | null > | null,
  or?: Array< ModelSongFilterInput | null > | null,
  not?: ModelSongFilterInput | null,
};

export type ModelShowFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  date?: ModelStringInput | null,
  dateTimeStamp?: ModelFloatInput | null,
  venueName?: ModelStringInput | null,
  venueAddress?: ModelStringInput | null,
  venueCity?: ModelStringInput | null,
  venueState?: ModelStringInput | null,
  venueZipcode?: ModelStringInput | null,
  showPictureUrl?: ModelStringInput | null,
  imGoing?: ModelBooleanInput | null,
  goingCount?: ModelIntInput | null,
  openingStatus?: ModelShowOpeningStatusInput | null,
  venueId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShowFilterInput | null > | null,
  or?: Array< ModelShowFilterInput | null > | null,
  not?: ModelShowFilterInput | null,
};

export type ModelBandShowFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  bandId?: ModelIDInput | null,
  showId?: ModelIDInput | null,
  status?: ModelBandShowStatusInput | null,
  bandGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandShowFilterInput | null > | null,
  or?: Array< ModelBandShowFilterInput | null > | null,
  not?: ModelBandShowFilterInput | null,
};

export type ModelVenueFilterInput = {
  id?: ModelIDInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  profileImageUrl?: ModelStringInput | null,
  foursquareId?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  name?: ModelStringInput | null,
  state?: ModelStringInput | null,
  status?: ModelVenueStatusInput | null,
  updatedAt?: ModelStringInput | null,
  isBlocked?: ModelBooleanInput | null,
  and?: Array< ModelVenueFilterInput | null > | null,
  or?: Array< ModelVenueFilterInput | null > | null,
  not?: ModelVenueFilterInput | null,
};

export type ModelVenueConnection = {
  __typename: "ModelVenueConnection",
  items:  Array<Venue | null >,
  nextToken?: string | null,
};

export type ModelBandFanFilterInput = {
  id?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandFanFilterInput | null > | null,
  or?: Array< ModelBandFanFilterInput | null > | null,
  not?: ModelBandFanFilterInput | null,
};

export type ModelBandMapPointFilterInput = {
  id?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  creatorId?: ModelIDInput | null,
  bandGroup?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  radius?: ModelFloatInput | null,
  order?: ModelIntInput | null,
  dateStart?: ModelStringInput | null,
  dateEnd?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandMapPointFilterInput | null > | null,
  or?: Array< ModelBandMapPointFilterInput | null > | null,
  not?: ModelBandMapPointFilterInput | null,
};

export type ModelBandTourFilterInput = {
  id?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandTourFilterInput | null > | null,
  or?: Array< ModelBandTourFilterInput | null > | null,
  not?: ModelBandTourFilterInput | null,
};

export type ModelBandTourPointFilterInput = {
  id?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  bandTourId?: ModelIDInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandTourPointFilterInput | null > | null,
  or?: Array< ModelBandTourPointFilterInput | null > | null,
  not?: ModelBandTourPointFilterInput | null,
};

export type ModelShowGoingFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  showId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShowGoingFilterInput | null > | null,
  or?: Array< ModelShowGoingFilterInput | null > | null,
  not?: ModelShowGoingFilterInput | null,
};

export type ModelFriendshipRequestFilterInput = {
  id?: ModelIDInput | null,
  senderId?: ModelIDInput | null,
  receiverId?: ModelIDInput | null,
  status?: ModelFriendshipRequestStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFriendshipRequestFilterInput | null > | null,
  or?: Array< ModelFriendshipRequestFilterInput | null > | null,
  not?: ModelFriendshipRequestFilterInput | null,
};

export type ModelFriendshipFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  friendId?: ModelIDInput | null,
  isHidden?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFriendshipFilterInput | null > | null,
  or?: Array< ModelFriendshipFilterInput | null > | null,
  not?: ModelFriendshipFilterInput | null,
};

export type ModelBandHighlightFilterInput = {
  id?: ModelIDInput | null,
  bandId?: ModelIDInput | null,
  createdById?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBandHighlightFilterInput | null > | null,
  or?: Array< ModelBandHighlightFilterInput | null > | null,
  not?: ModelBandHighlightFilterInput | null,
};

export type FindableUserConnection = {
  __typename: "FindableUserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type FindableBandConnection = {
  __typename: "FindableBandConnection",
  items?:  Array<Band | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type FindableShowConnection = {
  __typename: "FindableShowConnection",
  items?:  Array<Show | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type FindableVenueConnection = {
  __typename: "FindableVenueConnection",
  items?:  Array<Venue | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    userId: string,
    firstName: string,
    lastName: string,
    username: string,
    lowerUsername?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    bandPostCheers?:  {
      __typename: "ModelBandPostCheerConnection",
      items:  Array< {
        __typename: "BandPostCheer",
        id: string,
        bandPostId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMemberRequests?:  {
      __typename: "ModelBandMemberRequestConnection",
      items:  Array< {
        __typename: "BandMemberRequest",
        id: string,
        userId?: string | null,
        bandId: string,
        status: BandMemberRequestStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        creatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMembers?:  {
      __typename: "ModelBandMemberConnection",
      items:  Array< {
        __typename: "BandMember",
        id: string,
        instrument?: string | null,
        creatorId?: string | null,
        bandId: string,
        userId: string,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandFans?:  {
      __typename: "ModelBandFanConnection",
      items:  Array< {
        __typename: "BandFan",
        id: string,
        bandId: string,
        userId: string,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isFriend?: boolean | null,
    friendships?:  {
      __typename: "ModelFriendshipConnection",
      items:  Array< {
        __typename: "Friendship",
        id: string,
        userId: string,
        friendId: string,
        isHidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sentFriendRequests?:  {
      __typename: "ModelFriendshipRequestConnection",
      items:  Array< {
        __typename: "FriendshipRequest",
        id: string,
        senderId: string,
        receiverId: string,
        status: FriendshipRequestStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedFriendRequests?:  {
      __typename: "ModelFriendshipRequestConnection",
      items:  Array< {
        __typename: "FriendshipRequest",
        id: string,
        senderId: string,
        receiverId: string,
        status: FriendshipRequestStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fanCount?: number | null,
    bandHighlights?:  {
      __typename: "ModelBandHighlightConnection",
      items:  Array< {
        __typename: "BandHighlight",
        id: string,
        bandId: string,
        createdById: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    showGoings?:  {
      __typename: "ModelShowGoingConnection",
      items:  Array< {
        __typename: "ShowGoing",
        id: string,
        userId: string,
        showId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    nonce?: string | null,
    secretToken?: string | null,
    preferencesId?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      id?: string | null,
      userId?: string | null,
      wantsEmailNotifications?: boolean | null,
      wantsBandRequestEmailNotif?: boolean | null,
      wantsBandFriendEmailNotif?: boolean | null,
      wantsUserFollowEmailNotif?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    userId: string,
    firstName: string,
    lastName: string,
    username: string,
    lowerUsername?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    bandPostCheers?:  {
      __typename: "ModelBandPostCheerConnection",
      items:  Array< {
        __typename: "BandPostCheer",
        id: string,
        bandPostId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMemberRequests?:  {
      __typename: "ModelBandMemberRequestConnection",
      items:  Array< {
        __typename: "BandMemberRequest",
        id: string,
        userId?: string | null,
        bandId: string,
        status: BandMemberRequestStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        creatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMembers?:  {
      __typename: "ModelBandMemberConnection",
      items:  Array< {
        __typename: "BandMember",
        id: string,
        instrument?: string | null,
        creatorId?: string | null,
        bandId: string,
        userId: string,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandFans?:  {
      __typename: "ModelBandFanConnection",
      items:  Array< {
        __typename: "BandFan",
        id: string,
        bandId: string,
        userId: string,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isFriend?: boolean | null,
    friendships?:  {
      __typename: "ModelFriendshipConnection",
      items:  Array< {
        __typename: "Friendship",
        id: string,
        userId: string,
        friendId: string,
        isHidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sentFriendRequests?:  {
      __typename: "ModelFriendshipRequestConnection",
      items:  Array< {
        __typename: "FriendshipRequest",
        id: string,
        senderId: string,
        receiverId: string,
        status: FriendshipRequestStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedFriendRequests?:  {
      __typename: "ModelFriendshipRequestConnection",
      items:  Array< {
        __typename: "FriendshipRequest",
        id: string,
        senderId: string,
        receiverId: string,
        status: FriendshipRequestStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fanCount?: number | null,
    bandHighlights?:  {
      __typename: "ModelBandHighlightConnection",
      items:  Array< {
        __typename: "BandHighlight",
        id: string,
        bandId: string,
        createdById: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    showGoings?:  {
      __typename: "ModelShowGoingConnection",
      items:  Array< {
        __typename: "ShowGoing",
        id: string,
        userId: string,
        showId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    nonce?: string | null,
    secretToken?: string | null,
    preferencesId?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      id?: string | null,
      userId?: string | null,
      wantsEmailNotifications?: boolean | null,
      wantsBandRequestEmailNotif?: boolean | null,
      wantsBandFriendEmailNotif?: boolean | null,
      wantsUserFollowEmailNotif?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    userId: string,
    firstName: string,
    lastName: string,
    username: string,
    lowerUsername?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    bandPostCheers?:  {
      __typename: "ModelBandPostCheerConnection",
      items:  Array< {
        __typename: "BandPostCheer",
        id: string,
        bandPostId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMemberRequests?:  {
      __typename: "ModelBandMemberRequestConnection",
      items:  Array< {
        __typename: "BandMemberRequest",
        id: string,
        userId?: string | null,
        bandId: string,
        status: BandMemberRequestStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        creatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMembers?:  {
      __typename: "ModelBandMemberConnection",
      items:  Array< {
        __typename: "BandMember",
        id: string,
        instrument?: string | null,
        creatorId?: string | null,
        bandId: string,
        userId: string,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandFans?:  {
      __typename: "ModelBandFanConnection",
      items:  Array< {
        __typename: "BandFan",
        id: string,
        bandId: string,
        userId: string,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isFriend?: boolean | null,
    friendships?:  {
      __typename: "ModelFriendshipConnection",
      items:  Array< {
        __typename: "Friendship",
        id: string,
        userId: string,
        friendId: string,
        isHidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sentFriendRequests?:  {
      __typename: "ModelFriendshipRequestConnection",
      items:  Array< {
        __typename: "FriendshipRequest",
        id: string,
        senderId: string,
        receiverId: string,
        status: FriendshipRequestStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedFriendRequests?:  {
      __typename: "ModelFriendshipRequestConnection",
      items:  Array< {
        __typename: "FriendshipRequest",
        id: string,
        senderId: string,
        receiverId: string,
        status: FriendshipRequestStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fanCount?: number | null,
    bandHighlights?:  {
      __typename: "ModelBandHighlightConnection",
      items:  Array< {
        __typename: "BandHighlight",
        id: string,
        bandId: string,
        createdById: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    showGoings?:  {
      __typename: "ModelShowGoingConnection",
      items:  Array< {
        __typename: "ShowGoing",
        id: string,
        userId: string,
        showId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    nonce?: string | null,
    secretToken?: string | null,
    preferencesId?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      id?: string | null,
      userId?: string | null,
      wantsEmailNotifications?: boolean | null,
      wantsBandRequestEmailNotif?: boolean | null,
      wantsBandFriendEmailNotif?: boolean | null,
      wantsUserFollowEmailNotif?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserPreferencesMutationVariables = {
  input: CreateUserPreferencesInput,
  condition?: ModelUserPreferencesConditionInput | null,
};

export type CreateUserPreferencesMutation = {
  createUserPreferences?:  {
    __typename: "UserPreferences",
    id?: string | null,
    userId?: string | null,
    wantsEmailNotifications?: boolean | null,
    wantsBandRequestEmailNotif?: boolean | null,
    wantsBandFriendEmailNotif?: boolean | null,
    wantsUserFollowEmailNotif?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserPreferencesMutationVariables = {
  input: UpdateUserPreferencesInput,
  condition?: ModelUserPreferencesConditionInput | null,
};

export type UpdateUserPreferencesMutation = {
  updateUserPreferences?:  {
    __typename: "UserPreferences",
    id?: string | null,
    userId?: string | null,
    wantsEmailNotifications?: boolean | null,
    wantsBandRequestEmailNotif?: boolean | null,
    wantsBandFriendEmailNotif?: boolean | null,
    wantsUserFollowEmailNotif?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserPreferencesMutationVariables = {
  input: DeleteUserPreferencesInput,
  condition?: ModelUserPreferencesConditionInput | null,
};

export type DeleteUserPreferencesMutation = {
  deleteUserPreferences?:  {
    __typename: "UserPreferences",
    id?: string | null,
    userId?: string | null,
    wantsEmailNotifications?: boolean | null,
    wantsBandRequestEmailNotif?: boolean | null,
    wantsBandFriendEmailNotif?: boolean | null,
    wantsUserFollowEmailNotif?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBandMutationVariables = {
  input: CreateBandInput,
  condition?: ModelBandConditionInput | null,
};

export type CreateBandMutation = {
  createBand?:  {
    __typename: "Band",
    id: string,
    name: string,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    zipcode?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    fanCount?: number | null,
    slug?: string | null,
    bandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: BandStatus | null,
    songs?:  {
      __typename: "ModelSongConnection",
      items:  Array< {
        __typename: "Song",
        id?: string | null,
        name?: string | null,
        albumName?: string | null,
        durationSeconds?: number | null,
        songPictureUrl?: string | null,
        order?: number | null,
        songUrl?: string | null,
        status?: SongStatus | null,
        bandId?: string | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pendingBandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandPosts?:  {
      __typename: "ModelBandPostConnection",
      items:  Array< {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandPostTimelines?:  {
      __typename: "ModelBandPostTimelineConnection",
      items:  Array< {
        __typename: "BandPostTimeline",
        id: string,
        userId: string,
        bandId: string,
        bandPostId: string,
        createdAt: string,
        status: BandPostTimelineStatus,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    highlights?:  {
      __typename: "ModelBandHighlightConnection",
      items:  Array< {
        __typename: "BandHighlight",
        id: string,
        bandId: string,
        createdById: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandFans?:  {
      __typename: "ModelBandFanConnection",
      items:  Array< {
        __typename: "BandFan",
        id: string,
        bandId: string,
        userId: string,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMembers?:  {
      __typename: "ModelBandMemberConnection",
      items:  Array< {
        __typename: "BandMember",
        id: string,
        instrument?: string | null,
        creatorId?: string | null,
        bandId: string,
        userId: string,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    BandMemberRequests?:  {
      __typename: "ModelBandMemberRequestConnection",
      items:  Array< {
        __typename: "BandMemberRequest",
        id: string,
        userId?: string | null,
        bandId: string,
        status: BandMemberRequestStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        creatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    hasPendingBandShow?: boolean | null,
    showTradeStatus?: ShowTradeStatus | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    bandMapPoints?:  {
      __typename: "ModelBandMapPointConnection",
      items:  Array< {
        __typename: "BandMapPoint",
        id?: string | null,
        bandId?: string | null,
        name?: string | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        radius?: number | null,
        order?: number | null,
        dateStart?: string | null,
        dateEnd?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandTours?:  {
      __typename: "ModelBandTourConnection",
      items:  Array< {
        __typename: "BandTour",
        id: string,
        bandId: string,
        startDate: string,
        endDate: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandTourPoints?:  {
      __typename: "ModelBandTourPointConnection",
      items:  Array< {
        __typename: "BandTourPoint",
        id: string,
        bandId: string,
        bandTourId: string,
        latitude: number,
        longitude: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBandMutationVariables = {
  input: UpdateBandInput,
  condition?: ModelBandConditionInput | null,
};

export type UpdateBandMutation = {
  updateBand?:  {
    __typename: "Band",
    id: string,
    name: string,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    zipcode?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    fanCount?: number | null,
    slug?: string | null,
    bandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: BandStatus | null,
    songs?:  {
      __typename: "ModelSongConnection",
      items:  Array< {
        __typename: "Song",
        id?: string | null,
        name?: string | null,
        albumName?: string | null,
        durationSeconds?: number | null,
        songPictureUrl?: string | null,
        order?: number | null,
        songUrl?: string | null,
        status?: SongStatus | null,
        bandId?: string | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pendingBandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandPosts?:  {
      __typename: "ModelBandPostConnection",
      items:  Array< {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandPostTimelines?:  {
      __typename: "ModelBandPostTimelineConnection",
      items:  Array< {
        __typename: "BandPostTimeline",
        id: string,
        userId: string,
        bandId: string,
        bandPostId: string,
        createdAt: string,
        status: BandPostTimelineStatus,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    highlights?:  {
      __typename: "ModelBandHighlightConnection",
      items:  Array< {
        __typename: "BandHighlight",
        id: string,
        bandId: string,
        createdById: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandFans?:  {
      __typename: "ModelBandFanConnection",
      items:  Array< {
        __typename: "BandFan",
        id: string,
        bandId: string,
        userId: string,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMembers?:  {
      __typename: "ModelBandMemberConnection",
      items:  Array< {
        __typename: "BandMember",
        id: string,
        instrument?: string | null,
        creatorId?: string | null,
        bandId: string,
        userId: string,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    BandMemberRequests?:  {
      __typename: "ModelBandMemberRequestConnection",
      items:  Array< {
        __typename: "BandMemberRequest",
        id: string,
        userId?: string | null,
        bandId: string,
        status: BandMemberRequestStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        creatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    hasPendingBandShow?: boolean | null,
    showTradeStatus?: ShowTradeStatus | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    bandMapPoints?:  {
      __typename: "ModelBandMapPointConnection",
      items:  Array< {
        __typename: "BandMapPoint",
        id?: string | null,
        bandId?: string | null,
        name?: string | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        radius?: number | null,
        order?: number | null,
        dateStart?: string | null,
        dateEnd?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandTours?:  {
      __typename: "ModelBandTourConnection",
      items:  Array< {
        __typename: "BandTour",
        id: string,
        bandId: string,
        startDate: string,
        endDate: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandTourPoints?:  {
      __typename: "ModelBandTourPointConnection",
      items:  Array< {
        __typename: "BandTourPoint",
        id: string,
        bandId: string,
        bandTourId: string,
        latitude: number,
        longitude: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBandMutationVariables = {
  input: DeleteBandInput,
  condition?: ModelBandConditionInput | null,
};

export type DeleteBandMutation = {
  deleteBand?:  {
    __typename: "Band",
    id: string,
    name: string,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    zipcode?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    fanCount?: number | null,
    slug?: string | null,
    bandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: BandStatus | null,
    songs?:  {
      __typename: "ModelSongConnection",
      items:  Array< {
        __typename: "Song",
        id?: string | null,
        name?: string | null,
        albumName?: string | null,
        durationSeconds?: number | null,
        songPictureUrl?: string | null,
        order?: number | null,
        songUrl?: string | null,
        status?: SongStatus | null,
        bandId?: string | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pendingBandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandPosts?:  {
      __typename: "ModelBandPostConnection",
      items:  Array< {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandPostTimelines?:  {
      __typename: "ModelBandPostTimelineConnection",
      items:  Array< {
        __typename: "BandPostTimeline",
        id: string,
        userId: string,
        bandId: string,
        bandPostId: string,
        createdAt: string,
        status: BandPostTimelineStatus,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    highlights?:  {
      __typename: "ModelBandHighlightConnection",
      items:  Array< {
        __typename: "BandHighlight",
        id: string,
        bandId: string,
        createdById: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandFans?:  {
      __typename: "ModelBandFanConnection",
      items:  Array< {
        __typename: "BandFan",
        id: string,
        bandId: string,
        userId: string,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMembers?:  {
      __typename: "ModelBandMemberConnection",
      items:  Array< {
        __typename: "BandMember",
        id: string,
        instrument?: string | null,
        creatorId?: string | null,
        bandId: string,
        userId: string,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    BandMemberRequests?:  {
      __typename: "ModelBandMemberRequestConnection",
      items:  Array< {
        __typename: "BandMemberRequest",
        id: string,
        userId?: string | null,
        bandId: string,
        status: BandMemberRequestStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        creatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    hasPendingBandShow?: boolean | null,
    showTradeStatus?: ShowTradeStatus | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    bandMapPoints?:  {
      __typename: "ModelBandMapPointConnection",
      items:  Array< {
        __typename: "BandMapPoint",
        id?: string | null,
        bandId?: string | null,
        name?: string | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        radius?: number | null,
        order?: number | null,
        dateStart?: string | null,
        dateEnd?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandTours?:  {
      __typename: "ModelBandTourConnection",
      items:  Array< {
        __typename: "BandTour",
        id: string,
        bandId: string,
        startDate: string,
        endDate: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandTourPoints?:  {
      __typename: "ModelBandTourPointConnection",
      items:  Array< {
        __typename: "BandTourPoint",
        id: string,
        bandId: string,
        bandTourId: string,
        latitude: number,
        longitude: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBandMemberMutationVariables = {
  input: CreateBandMemberInput,
  condition?: ModelBandMemberConditionInput | null,
};

export type CreateBandMemberMutation = {
  createBandMember?:  {
    __typename: "BandMember",
    id: string,
    instrument?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandId: string,
    userId: string,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBandMemberMutationVariables = {
  input: UpdateBandMemberInput,
  condition?: ModelBandMemberConditionInput | null,
};

export type UpdateBandMemberMutation = {
  updateBandMember?:  {
    __typename: "BandMember",
    id: string,
    instrument?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandId: string,
    userId: string,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBandMemberMutationVariables = {
  input: DeleteBandMemberInput,
  condition?: ModelBandMemberConditionInput | null,
};

export type DeleteBandMemberMutation = {
  deleteBandMember?:  {
    __typename: "BandMember",
    id: string,
    instrument?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandId: string,
    userId: string,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBandMemberRequestMutationVariables = {
  input: CreateBandMemberRequestInput,
  condition?: ModelBandMemberRequestConditionInput | null,
};

export type CreateBandMemberRequestMutation = {
  createBandMemberRequest?:  {
    __typename: "BandMemberRequest",
    id: string,
    userId?: string | null,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: BandMemberRequestStatus,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    creatorId?: string | null,
  } | null,
};

export type UpdateBandMemberRequestMutationVariables = {
  input: UpdateBandMemberRequestInput,
  condition?: ModelBandMemberRequestConditionInput | null,
};

export type UpdateBandMemberRequestMutation = {
  updateBandMemberRequest?:  {
    __typename: "BandMemberRequest",
    id: string,
    userId?: string | null,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: BandMemberRequestStatus,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    creatorId?: string | null,
  } | null,
};

export type DeleteBandMemberRequestMutationVariables = {
  input: DeleteBandMemberRequestInput,
  condition?: ModelBandMemberRequestConditionInput | null,
};

export type DeleteBandMemberRequestMutation = {
  deleteBandMemberRequest?:  {
    __typename: "BandMemberRequest",
    id: string,
    userId?: string | null,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: BandMemberRequestStatus,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    creatorId?: string | null,
  } | null,
};

export type CreateBandPostMutationVariables = {
  input: CreateBandPostInput,
  condition?: ModelBandPostConditionInput | null,
};

export type CreateBandPostMutation = {
  createBandPost?:  {
    __typename: "BandPost",
    id: string,
    postImageUrl?: string | null,
    postImageUrls?: Array< string | null > | null,
    content?: string | null,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandPostCheers?:  {
      __typename: "ModelBandPostCheerConnection",
      items:  Array< {
        __typename: "BandPostCheer",
        id: string,
        bandPostId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    isCheered?: boolean | null,
    numCheers?: number | null,
    bandGroup?: string | null,
    bandPostTimelines?:  {
      __typename: "ModelBandPostTimelineConnection",
      items:  Array< {
        __typename: "BandPostTimeline",
        id: string,
        userId: string,
        bandId: string,
        bandPostId: string,
        createdAt: string,
        status: BandPostTimelineStatus,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    userId?: string | null,
  } | null,
};

export type UpdateBandPostMutationVariables = {
  input: UpdateBandPostInput,
  condition?: ModelBandPostConditionInput | null,
};

export type UpdateBandPostMutation = {
  updateBandPost?:  {
    __typename: "BandPost",
    id: string,
    postImageUrl?: string | null,
    postImageUrls?: Array< string | null > | null,
    content?: string | null,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandPostCheers?:  {
      __typename: "ModelBandPostCheerConnection",
      items:  Array< {
        __typename: "BandPostCheer",
        id: string,
        bandPostId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    isCheered?: boolean | null,
    numCheers?: number | null,
    bandGroup?: string | null,
    bandPostTimelines?:  {
      __typename: "ModelBandPostTimelineConnection",
      items:  Array< {
        __typename: "BandPostTimeline",
        id: string,
        userId: string,
        bandId: string,
        bandPostId: string,
        createdAt: string,
        status: BandPostTimelineStatus,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    userId?: string | null,
  } | null,
};

export type DeleteBandPostMutationVariables = {
  input: DeleteBandPostInput,
  condition?: ModelBandPostConditionInput | null,
};

export type DeleteBandPostMutation = {
  deleteBandPost?:  {
    __typename: "BandPost",
    id: string,
    postImageUrl?: string | null,
    postImageUrls?: Array< string | null > | null,
    content?: string | null,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandPostCheers?:  {
      __typename: "ModelBandPostCheerConnection",
      items:  Array< {
        __typename: "BandPostCheer",
        id: string,
        bandPostId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    isCheered?: boolean | null,
    numCheers?: number | null,
    bandGroup?: string | null,
    bandPostTimelines?:  {
      __typename: "ModelBandPostTimelineConnection",
      items:  Array< {
        __typename: "BandPostTimeline",
        id: string,
        userId: string,
        bandId: string,
        bandPostId: string,
        createdAt: string,
        status: BandPostTimelineStatus,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    userId?: string | null,
  } | null,
};

export type CreateBandPostTimelineMutationVariables = {
  input: CreateBandPostTimelineInput,
  condition?: ModelBandPostTimelineConditionInput | null,
};

export type CreateBandPostTimelineMutation = {
  createBandPostTimeline?:  {
    __typename: "BandPostTimeline",
    id: string,
    userId: string,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandPostId: string,
    bandPost:  {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    },
    createdAt: string,
    status: BandPostTimelineStatus,
    updatedAt: string,
  } | null,
};

export type UpdateBandPostTimelineMutationVariables = {
  input: UpdateBandPostTimelineInput,
  condition?: ModelBandPostTimelineConditionInput | null,
};

export type UpdateBandPostTimelineMutation = {
  updateBandPostTimeline?:  {
    __typename: "BandPostTimeline",
    id: string,
    userId: string,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandPostId: string,
    bandPost:  {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    },
    createdAt: string,
    status: BandPostTimelineStatus,
    updatedAt: string,
  } | null,
};

export type DeleteBandPostTimelineMutationVariables = {
  input: DeleteBandPostTimelineInput,
  condition?: ModelBandPostTimelineConditionInput | null,
};

export type DeleteBandPostTimelineMutation = {
  deleteBandPostTimeline?:  {
    __typename: "BandPostTimeline",
    id: string,
    userId: string,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandPostId: string,
    bandPost:  {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    },
    createdAt: string,
    status: BandPostTimelineStatus,
    updatedAt: string,
  } | null,
};

export type CreateBandPostCheerMutationVariables = {
  input: CreateBandPostCheerInput,
  condition?: ModelBandPostCheerConditionInput | null,
};

export type CreateBandPostCheerMutation = {
  createBandPostCheer?:  {
    __typename: "BandPostCheer",
    id: string,
    bandPostId: string,
    bandPost?:  {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBandPostCheerMutationVariables = {
  input: UpdateBandPostCheerInput,
  condition?: ModelBandPostCheerConditionInput | null,
};

export type UpdateBandPostCheerMutation = {
  updateBandPostCheer?:  {
    __typename: "BandPostCheer",
    id: string,
    bandPostId: string,
    bandPost?:  {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBandPostCheerMutationVariables = {
  input: DeleteBandPostCheerInput,
  condition?: ModelBandPostCheerConditionInput | null,
};

export type DeleteBandPostCheerMutation = {
  deleteBandPostCheer?:  {
    __typename: "BandPostCheer",
    id: string,
    bandPostId: string,
    bandPost?:  {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSongMutationVariables = {
  input: CreateSongInput,
  condition?: ModelSongConditionInput | null,
};

export type CreateSongMutation = {
  createSong?:  {
    __typename: "Song",
    id?: string | null,
    name?: string | null,
    albumName?: string | null,
    durationSeconds?: number | null,
    songPictureUrl?: string | null,
    order?: number | null,
    songUrl?: string | null,
    status?: SongStatus | null,
    bandId?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSongMutationVariables = {
  input: UpdateSongInput,
  condition?: ModelSongConditionInput | null,
};

export type UpdateSongMutation = {
  updateSong?:  {
    __typename: "Song",
    id?: string | null,
    name?: string | null,
    albumName?: string | null,
    durationSeconds?: number | null,
    songPictureUrl?: string | null,
    order?: number | null,
    songUrl?: string | null,
    status?: SongStatus | null,
    bandId?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSongMutationVariables = {
  input: DeleteSongInput,
  condition?: ModelSongConditionInput | null,
};

export type DeleteSongMutation = {
  deleteSong?:  {
    __typename: "Song",
    id?: string | null,
    name?: string | null,
    albumName?: string | null,
    durationSeconds?: number | null,
    songPictureUrl?: string | null,
    order?: number | null,
    songUrl?: string | null,
    status?: SongStatus | null,
    bandId?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateShowMutationVariables = {
  input: CreateShowInput,
  condition?: ModelShowConditionInput | null,
};

export type CreateShowMutation = {
  createShow?:  {
    __typename: "Show",
    id: string,
    name: string,
    description?: string | null,
    date: string,
    dateTimeStamp?: number | null,
    venueName?: string | null,
    venueAddress?: string | null,
    venueCity?: string | null,
    venueState?: string | null,
    venueZipcode?: string | null,
    showPictureUrl?: string | null,
    imGoing?: boolean | null,
    goingCount?: number | null,
    bandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    showGoings?:  {
      __typename: "ModelShowGoingConnection",
      items:  Array< {
        __typename: "ShowGoing",
        id: string,
        userId: string,
        showId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    openingStatus?: ShowOpeningStatus | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    venueId?: string | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      address: string,
      city: string,
      createdAt: string,
      icon?: string | null,
      profileImageUrl?: string | null,
      foursquareId: string,
      latitude: number,
      longitude: number,
      name: string,
      state: string,
      status?: VenueStatus | null,
      updatedAt: string,
      isBlocked?: boolean | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      shows?:  {
        __typename: "ModelShowConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateShowMutationVariables = {
  input: UpdateShowInput,
  condition?: ModelShowConditionInput | null,
};

export type UpdateShowMutation = {
  updateShow?:  {
    __typename: "Show",
    id: string,
    name: string,
    description?: string | null,
    date: string,
    dateTimeStamp?: number | null,
    venueName?: string | null,
    venueAddress?: string | null,
    venueCity?: string | null,
    venueState?: string | null,
    venueZipcode?: string | null,
    showPictureUrl?: string | null,
    imGoing?: boolean | null,
    goingCount?: number | null,
    bandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    showGoings?:  {
      __typename: "ModelShowGoingConnection",
      items:  Array< {
        __typename: "ShowGoing",
        id: string,
        userId: string,
        showId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    openingStatus?: ShowOpeningStatus | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    venueId?: string | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      address: string,
      city: string,
      createdAt: string,
      icon?: string | null,
      profileImageUrl?: string | null,
      foursquareId: string,
      latitude: number,
      longitude: number,
      name: string,
      state: string,
      status?: VenueStatus | null,
      updatedAt: string,
      isBlocked?: boolean | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      shows?:  {
        __typename: "ModelShowConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteShowMutationVariables = {
  input: DeleteShowInput,
  condition?: ModelShowConditionInput | null,
};

export type DeleteShowMutation = {
  deleteShow?:  {
    __typename: "Show",
    id: string,
    name: string,
    description?: string | null,
    date: string,
    dateTimeStamp?: number | null,
    venueName?: string | null,
    venueAddress?: string | null,
    venueCity?: string | null,
    venueState?: string | null,
    venueZipcode?: string | null,
    showPictureUrl?: string | null,
    imGoing?: boolean | null,
    goingCount?: number | null,
    bandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    showGoings?:  {
      __typename: "ModelShowGoingConnection",
      items:  Array< {
        __typename: "ShowGoing",
        id: string,
        userId: string,
        showId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    openingStatus?: ShowOpeningStatus | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    venueId?: string | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      address: string,
      city: string,
      createdAt: string,
      icon?: string | null,
      profileImageUrl?: string | null,
      foursquareId: string,
      latitude: number,
      longitude: number,
      name: string,
      state: string,
      status?: VenueStatus | null,
      updatedAt: string,
      isBlocked?: boolean | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      shows?:  {
        __typename: "ModelShowConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBandShowMutationVariables = {
  input: CreateBandShowInput,
  condition?: ModelBandShowConditionInput | null,
};

export type CreateBandShowMutation = {
  createBandShow?:  {
    __typename: "BandShow",
    id: string,
    date: string,
    bandId: string,
    showId: string,
    status: BandShowStatus,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    show?:  {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBandShowMutationVariables = {
  input: UpdateBandShowInput,
  condition?: ModelBandShowConditionInput | null,
};

export type UpdateBandShowMutation = {
  updateBandShow?:  {
    __typename: "BandShow",
    id: string,
    date: string,
    bandId: string,
    showId: string,
    status: BandShowStatus,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    show?:  {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBandShowMutationVariables = {
  input: DeleteBandShowInput,
  condition?: ModelBandShowConditionInput | null,
};

export type DeleteBandShowMutation = {
  deleteBandShow?:  {
    __typename: "BandShow",
    id: string,
    date: string,
    bandId: string,
    showId: string,
    status: BandShowStatus,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    show?:  {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVenueMutationVariables = {
  input: CreateVenueInput,
  condition?: ModelVenueConditionInput | null,
};

export type CreateVenueMutation = {
  createVenue?:  {
    __typename: "Venue",
    id: string,
    address: string,
    city: string,
    createdAt: string,
    icon?: string | null,
    profileImageUrl?: string | null,
    foursquareId: string,
    latitude: number,
    longitude: number,
    name: string,
    state: string,
    status?: VenueStatus | null,
    updatedAt: string,
    isBlocked?: boolean | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    shows?:  {
      __typename: "ModelShowConnection",
      items:  Array< {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateVenueMutationVariables = {
  input: UpdateVenueInput,
  condition?: ModelVenueConditionInput | null,
};

export type UpdateVenueMutation = {
  updateVenue?:  {
    __typename: "Venue",
    id: string,
    address: string,
    city: string,
    createdAt: string,
    icon?: string | null,
    profileImageUrl?: string | null,
    foursquareId: string,
    latitude: number,
    longitude: number,
    name: string,
    state: string,
    status?: VenueStatus | null,
    updatedAt: string,
    isBlocked?: boolean | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    shows?:  {
      __typename: "ModelShowConnection",
      items:  Array< {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteVenueMutationVariables = {
  input: DeleteVenueInput,
  condition?: ModelVenueConditionInput | null,
};

export type DeleteVenueMutation = {
  deleteVenue?:  {
    __typename: "Venue",
    id: string,
    address: string,
    city: string,
    createdAt: string,
    icon?: string | null,
    profileImageUrl?: string | null,
    foursquareId: string,
    latitude: number,
    longitude: number,
    name: string,
    state: string,
    status?: VenueStatus | null,
    updatedAt: string,
    isBlocked?: boolean | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    shows?:  {
      __typename: "ModelShowConnection",
      items:  Array< {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateBandFanMutationVariables = {
  input: CreateBandFanInput,
  condition?: ModelBandFanConditionInput | null,
};

export type CreateBandFanMutation = {
  createBandFan?:  {
    __typename: "BandFan",
    id: string,
    bandId: string,
    userId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBandFanMutationVariables = {
  input: UpdateBandFanInput,
  condition?: ModelBandFanConditionInput | null,
};

export type UpdateBandFanMutation = {
  updateBandFan?:  {
    __typename: "BandFan",
    id: string,
    bandId: string,
    userId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBandFanMutationVariables = {
  input: DeleteBandFanInput,
  condition?: ModelBandFanConditionInput | null,
};

export type DeleteBandFanMutation = {
  deleteBandFan?:  {
    __typename: "BandFan",
    id: string,
    bandId: string,
    userId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBandMapPointMutationVariables = {
  input: CreateBandMapPointInput,
  condition?: ModelBandMapPointConditionInput | null,
};

export type CreateBandMapPointMutation = {
  createBandMapPoint?:  {
    __typename: "BandMapPoint",
    id?: string | null,
    bandId?: string | null,
    name?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    radius?: number | null,
    order?: number | null,
    dateStart?: string | null,
    dateEnd?: string | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBandMapPointMutationVariables = {
  input: UpdateBandMapPointInput,
  condition?: ModelBandMapPointConditionInput | null,
};

export type UpdateBandMapPointMutation = {
  updateBandMapPoint?:  {
    __typename: "BandMapPoint",
    id?: string | null,
    bandId?: string | null,
    name?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    radius?: number | null,
    order?: number | null,
    dateStart?: string | null,
    dateEnd?: string | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBandMapPointMutationVariables = {
  input: DeleteBandMapPointInput,
  condition?: ModelBandMapPointConditionInput | null,
};

export type DeleteBandMapPointMutation = {
  deleteBandMapPoint?:  {
    __typename: "BandMapPoint",
    id?: string | null,
    bandId?: string | null,
    name?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    radius?: number | null,
    order?: number | null,
    dateStart?: string | null,
    dateEnd?: string | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBandTourMutationVariables = {
  input: CreateBandTourInput,
  condition?: ModelBandTourConditionInput | null,
};

export type CreateBandTourMutation = {
  createBandTour?:  {
    __typename: "BandTour",
    id: string,
    bandId: string,
    startDate: string,
    endDate: string,
    bandTourPoints?:  {
      __typename: "ModelBandTourPointConnection",
      items:  Array< {
        __typename: "BandTourPoint",
        id: string,
        bandId: string,
        bandTourId: string,
        latitude: number,
        longitude: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBandTourMutationVariables = {
  input: UpdateBandTourInput,
  condition?: ModelBandTourConditionInput | null,
};

export type UpdateBandTourMutation = {
  updateBandTour?:  {
    __typename: "BandTour",
    id: string,
    bandId: string,
    startDate: string,
    endDate: string,
    bandTourPoints?:  {
      __typename: "ModelBandTourPointConnection",
      items:  Array< {
        __typename: "BandTourPoint",
        id: string,
        bandId: string,
        bandTourId: string,
        latitude: number,
        longitude: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBandTourMutationVariables = {
  input: DeleteBandTourInput,
  condition?: ModelBandTourConditionInput | null,
};

export type DeleteBandTourMutation = {
  deleteBandTour?:  {
    __typename: "BandTour",
    id: string,
    bandId: string,
    startDate: string,
    endDate: string,
    bandTourPoints?:  {
      __typename: "ModelBandTourPointConnection",
      items:  Array< {
        __typename: "BandTourPoint",
        id: string,
        bandId: string,
        bandTourId: string,
        latitude: number,
        longitude: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBandTourPointMutationVariables = {
  input: CreateBandTourPointInput,
  condition?: ModelBandTourPointConditionInput | null,
};

export type CreateBandTourPointMutation = {
  createBandTourPoint?:  {
    __typename: "BandTourPoint",
    id: string,
    bandId: string,
    bandTourId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandTour?:  {
      __typename: "BandTour",
      id: string,
      bandId: string,
      startDate: string,
      endDate: string,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      name: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    latitude: number,
    longitude: number,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBandTourPointMutationVariables = {
  input: UpdateBandTourPointInput,
  condition?: ModelBandTourPointConditionInput | null,
};

export type UpdateBandTourPointMutation = {
  updateBandTourPoint?:  {
    __typename: "BandTourPoint",
    id: string,
    bandId: string,
    bandTourId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandTour?:  {
      __typename: "BandTour",
      id: string,
      bandId: string,
      startDate: string,
      endDate: string,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      name: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    latitude: number,
    longitude: number,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBandTourPointMutationVariables = {
  input: DeleteBandTourPointInput,
  condition?: ModelBandTourPointConditionInput | null,
};

export type DeleteBandTourPointMutation = {
  deleteBandTourPoint?:  {
    __typename: "BandTourPoint",
    id: string,
    bandId: string,
    bandTourId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandTour?:  {
      __typename: "BandTour",
      id: string,
      bandId: string,
      startDate: string,
      endDate: string,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      name: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    latitude: number,
    longitude: number,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateShowGoingMutationVariables = {
  input: CreateShowGoingInput,
  condition?: ModelShowGoingConditionInput | null,
};

export type CreateShowGoingMutation = {
  createShowGoing?:  {
    __typename: "ShowGoing",
    id: string,
    userId: string,
    showId: string,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    show?:  {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateShowGoingMutationVariables = {
  input: UpdateShowGoingInput,
  condition?: ModelShowGoingConditionInput | null,
};

export type UpdateShowGoingMutation = {
  updateShowGoing?:  {
    __typename: "ShowGoing",
    id: string,
    userId: string,
    showId: string,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    show?:  {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteShowGoingMutationVariables = {
  input: DeleteShowGoingInput,
  condition?: ModelShowGoingConditionInput | null,
};

export type DeleteShowGoingMutation = {
  deleteShowGoing?:  {
    __typename: "ShowGoing",
    id: string,
    userId: string,
    showId: string,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    show?:  {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFriendshipRequestMutationVariables = {
  input: CreateFriendshipRequestInput,
  condition?: ModelFriendshipRequestConditionInput | null,
};

export type CreateFriendshipRequestMutation = {
  createFriendshipRequest?:  {
    __typename: "FriendshipRequest",
    id: string,
    senderId: string,
    receiverId: string,
    sender?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    receiver?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: FriendshipRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFriendshipRequestMutationVariables = {
  input: UpdateFriendshipRequestInput,
  condition?: ModelFriendshipRequestConditionInput | null,
};

export type UpdateFriendshipRequestMutation = {
  updateFriendshipRequest?:  {
    __typename: "FriendshipRequest",
    id: string,
    senderId: string,
    receiverId: string,
    sender?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    receiver?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: FriendshipRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFriendshipRequestMutationVariables = {
  input: DeleteFriendshipRequestInput,
  condition?: ModelFriendshipRequestConditionInput | null,
};

export type DeleteFriendshipRequestMutation = {
  deleteFriendshipRequest?:  {
    __typename: "FriendshipRequest",
    id: string,
    senderId: string,
    receiverId: string,
    sender?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    receiver?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: FriendshipRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFriendshipMutationVariables = {
  input: CreateFriendshipInput,
  condition?: ModelFriendshipConditionInput | null,
};

export type CreateFriendshipMutation = {
  createFriendship?:  {
    __typename: "Friendship",
    id: string,
    userId: string,
    friendId: string,
    isHidden?: boolean | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    friend?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFriendshipMutationVariables = {
  input: UpdateFriendshipInput,
  condition?: ModelFriendshipConditionInput | null,
};

export type UpdateFriendshipMutation = {
  updateFriendship?:  {
    __typename: "Friendship",
    id: string,
    userId: string,
    friendId: string,
    isHidden?: boolean | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    friend?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFriendshipMutationVariables = {
  input: DeleteFriendshipInput,
  condition?: ModelFriendshipConditionInput | null,
};

export type DeleteFriendshipMutation = {
  deleteFriendship?:  {
    __typename: "Friendship",
    id: string,
    userId: string,
    friendId: string,
    isHidden?: boolean | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    friend?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBandHighlightMutationVariables = {
  input: CreateBandHighlightInput,
  condition?: ModelBandHighlightConditionInput | null,
};

export type CreateBandHighlightMutation = {
  createBandHighlight?:  {
    __typename: "BandHighlight",
    id: string,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdById: string,
    creator?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBandHighlightMutationVariables = {
  input: UpdateBandHighlightInput,
  condition?: ModelBandHighlightConditionInput | null,
};

export type UpdateBandHighlightMutation = {
  updateBandHighlight?:  {
    __typename: "BandHighlight",
    id: string,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdById: string,
    creator?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBandHighlightMutationVariables = {
  input: DeleteBandHighlightInput,
  condition?: ModelBandHighlightConditionInput | null,
};

export type DeleteBandHighlightMutation = {
  deleteBandHighlight?:  {
    __typename: "BandHighlight",
    id: string,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdById: string,
    creator?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type InviteBandMemberMutationVariables = {
  userId: string,
  bandId: string,
};

export type InviteBandMemberMutation = {
  inviteBandMember?: string | null,
};

export type RespondToBandMemberRequestMutationVariables = {
  requestId: string,
  response: BandMemberRequestResponse,
};

export type RespondToBandMemberRequestMutation = {
  respondToBandMemberRequest?: string | null,
};

export type InviteBandShowMutationVariables = {
  showId: string,
  bandId: string,
};

export type InviteBandShowMutation = {
  inviteBandShow?: string | null,
};

export type RemoveBandShowMutationVariables = {
  bandShowId: string,
};

export type RemoveBandShowMutation = {
  removeBandShow?: string | null,
};

export type RemoveFriendshipMutationVariables = {
  friendId: string,
};

export type RemoveFriendshipMutation = {
  removeFriendship?: string | null,
};

export type AddFSVenueQueryVariables = {
  foursquareId: string,
};

export type AddFSVenueQuery = {
  addFSVenue?:  {
    __typename: "Venue",
    id: string,
    address: string,
    city: string,
    createdAt: string,
    icon?: string | null,
    profileImageUrl?: string | null,
    foursquareId: string,
    latitude: number,
    longitude: number,
    name: string,
    state: string,
    status?: VenueStatus | null,
    updatedAt: string,
    isBlocked?: boolean | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    shows?:  {
      __typename: "ModelShowConnection",
      items:  Array< {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    userId: string,
    firstName: string,
    lastName: string,
    username: string,
    lowerUsername?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    bandPostCheers?:  {
      __typename: "ModelBandPostCheerConnection",
      items:  Array< {
        __typename: "BandPostCheer",
        id: string,
        bandPostId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMemberRequests?:  {
      __typename: "ModelBandMemberRequestConnection",
      items:  Array< {
        __typename: "BandMemberRequest",
        id: string,
        userId?: string | null,
        bandId: string,
        status: BandMemberRequestStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        creatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMembers?:  {
      __typename: "ModelBandMemberConnection",
      items:  Array< {
        __typename: "BandMember",
        id: string,
        instrument?: string | null,
        creatorId?: string | null,
        bandId: string,
        userId: string,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandFans?:  {
      __typename: "ModelBandFanConnection",
      items:  Array< {
        __typename: "BandFan",
        id: string,
        bandId: string,
        userId: string,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isFriend?: boolean | null,
    friendships?:  {
      __typename: "ModelFriendshipConnection",
      items:  Array< {
        __typename: "Friendship",
        id: string,
        userId: string,
        friendId: string,
        isHidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sentFriendRequests?:  {
      __typename: "ModelFriendshipRequestConnection",
      items:  Array< {
        __typename: "FriendshipRequest",
        id: string,
        senderId: string,
        receiverId: string,
        status: FriendshipRequestStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedFriendRequests?:  {
      __typename: "ModelFriendshipRequestConnection",
      items:  Array< {
        __typename: "FriendshipRequest",
        id: string,
        senderId: string,
        receiverId: string,
        status: FriendshipRequestStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fanCount?: number | null,
    bandHighlights?:  {
      __typename: "ModelBandHighlightConnection",
      items:  Array< {
        __typename: "BandHighlight",
        id: string,
        bandId: string,
        createdById: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    showGoings?:  {
      __typename: "ModelShowGoingConnection",
      items:  Array< {
        __typename: "ShowGoing",
        id: string,
        userId: string,
        showId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    nonce?: string | null,
    secretToken?: string | null,
    preferencesId?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      id?: string | null,
      userId?: string | null,
      wantsEmailNotifications?: boolean | null,
      wantsBandRequestEmailNotif?: boolean | null,
      wantsBandFriendEmailNotif?: boolean | null,
      wantsUserFollowEmailNotif?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByUserIdQuery = {
  usersByUserId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByUsernameQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByUsernameQuery = {
  usersByUsername?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByLowerUsernameQueryVariables = {
  lowerUsername: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByLowerUsernameQuery = {
  usersByLowerUsername?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserPreferencesQueryVariables = {
  id: string,
};

export type GetUserPreferencesQuery = {
  getUserPreferences?:  {
    __typename: "UserPreferences",
    id?: string | null,
    userId?: string | null,
    wantsEmailNotifications?: boolean | null,
    wantsBandRequestEmailNotif?: boolean | null,
    wantsBandFriendEmailNotif?: boolean | null,
    wantsUserFollowEmailNotif?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserPreferencesQueryVariables = {
  filter?: ModelUserPreferencesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserPreferencesQuery = {
  listUserPreferences?:  {
    __typename: "ModelUserPreferencesConnection",
    items:  Array< {
      __typename: "UserPreferences",
      id?: string | null,
      userId?: string | null,
      wantsEmailNotifications?: boolean | null,
      wantsBandRequestEmailNotif?: boolean | null,
      wantsBandFriendEmailNotif?: boolean | null,
      wantsUserFollowEmailNotif?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserPreferencesByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserPreferencesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserPreferencesByUserQuery = {
  userPreferencesByUser?:  {
    __typename: "ModelUserPreferencesConnection",
    items:  Array< {
      __typename: "UserPreferences",
      id?: string | null,
      userId?: string | null,
      wantsEmailNotifications?: boolean | null,
      wantsBandRequestEmailNotif?: boolean | null,
      wantsBandFriendEmailNotif?: boolean | null,
      wantsUserFollowEmailNotif?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandQueryVariables = {
  id: string,
};

export type GetBandQuery = {
  getBand?:  {
    __typename: "Band",
    id: string,
    name: string,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    zipcode?: string | null,
    profilePictureUrl?: string | null,
    profileBannerUrl?: string | null,
    fanCount?: number | null,
    slug?: string | null,
    bandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: BandStatus | null,
    songs?:  {
      __typename: "ModelSongConnection",
      items:  Array< {
        __typename: "Song",
        id?: string | null,
        name?: string | null,
        albumName?: string | null,
        durationSeconds?: number | null,
        songPictureUrl?: string | null,
        order?: number | null,
        songUrl?: string | null,
        status?: SongStatus | null,
        bandId?: string | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pendingBandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandPosts?:  {
      __typename: "ModelBandPostConnection",
      items:  Array< {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandPostTimelines?:  {
      __typename: "ModelBandPostTimelineConnection",
      items:  Array< {
        __typename: "BandPostTimeline",
        id: string,
        userId: string,
        bandId: string,
        bandPostId: string,
        createdAt: string,
        status: BandPostTimelineStatus,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    highlights?:  {
      __typename: "ModelBandHighlightConnection",
      items:  Array< {
        __typename: "BandHighlight",
        id: string,
        bandId: string,
        createdById: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandFans?:  {
      __typename: "ModelBandFanConnection",
      items:  Array< {
        __typename: "BandFan",
        id: string,
        bandId: string,
        userId: string,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandMembers?:  {
      __typename: "ModelBandMemberConnection",
      items:  Array< {
        __typename: "BandMember",
        id: string,
        instrument?: string | null,
        creatorId?: string | null,
        bandId: string,
        userId: string,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    BandMemberRequests?:  {
      __typename: "ModelBandMemberRequestConnection",
      items:  Array< {
        __typename: "BandMemberRequest",
        id: string,
        userId?: string | null,
        bandId: string,
        status: BandMemberRequestStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        creatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    hasPendingBandShow?: boolean | null,
    showTradeStatus?: ShowTradeStatus | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    bandMapPoints?:  {
      __typename: "ModelBandMapPointConnection",
      items:  Array< {
        __typename: "BandMapPoint",
        id?: string | null,
        bandId?: string | null,
        name?: string | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        radius?: number | null,
        order?: number | null,
        dateStart?: string | null,
        dateEnd?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandTours?:  {
      __typename: "ModelBandTourConnection",
      items:  Array< {
        __typename: "BandTour",
        id: string,
        bandId: string,
        startDate: string,
        endDate: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    bandTourPoints?:  {
      __typename: "ModelBandTourPointConnection",
      items:  Array< {
        __typename: "BandTourPoint",
        id: string,
        bandId: string,
        bandTourId: string,
        latitude: number,
        longitude: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBandsQueryVariables = {
  filter?: ModelBandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandsQuery = {
  listBands?:  {
    __typename: "ModelBandConnection",
    items:  Array< {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandsBySlugQueryVariables = {
  slug: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandsBySlugQuery = {
  bandsBySlug?:  {
    __typename: "ModelBandConnection",
    items:  Array< {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandsByStatusQueryVariables = {
  status: BandStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandsByStatusQuery = {
  bandsByStatus?:  {
    __typename: "ModelBandConnection",
    items:  Array< {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandsByCreatorQueryVariables = {
  creatorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandsByCreatorQuery = {
  bandsByCreator?:  {
    __typename: "ModelBandConnection",
    items:  Array< {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandMemberQueryVariables = {
  id: string,
};

export type GetBandMemberQuery = {
  getBandMember?:  {
    __typename: "BandMember",
    id: string,
    instrument?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandId: string,
    userId: string,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBandMembersQueryVariables = {
  filter?: ModelBandMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandMembersQuery = {
  listBandMembers?:  {
    __typename: "ModelBandMemberConnection",
    items:  Array< {
      __typename: "BandMember",
      id: string,
      instrument?: string | null,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandId: string,
      userId: string,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandMembersByCreatorQueryVariables = {
  creatorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandMembersByCreatorQuery = {
  bandMembersByCreator?:  {
    __typename: "ModelBandMemberConnection",
    items:  Array< {
      __typename: "BandMember",
      id: string,
      instrument?: string | null,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandId: string,
      userId: string,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandMembersByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandMembersByBandQuery = {
  bandMembersByBand?:  {
    __typename: "ModelBandMemberConnection",
    items:  Array< {
      __typename: "BandMember",
      id: string,
      instrument?: string | null,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandId: string,
      userId: string,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandMembersByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandMembersByUserQuery = {
  bandMembersByUser?:  {
    __typename: "ModelBandMemberConnection",
    items:  Array< {
      __typename: "BandMember",
      id: string,
      instrument?: string | null,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandId: string,
      userId: string,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandMemberRequestQueryVariables = {
  id: string,
};

export type GetBandMemberRequestQuery = {
  getBandMemberRequest?:  {
    __typename: "BandMemberRequest",
    id: string,
    userId?: string | null,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: BandMemberRequestStatus,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    creatorId?: string | null,
  } | null,
};

export type ListBandMemberRequestsQueryVariables = {
  filter?: ModelBandMemberRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandMemberRequestsQuery = {
  listBandMemberRequests?:  {
    __typename: "ModelBandMemberRequestConnection",
    items:  Array< {
      __typename: "BandMemberRequest",
      id: string,
      userId?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: BandMemberRequestStatus,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      creatorId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandMemberRequestsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandMemberRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandMemberRequestsByUserQuery = {
  bandMemberRequestsByUser?:  {
    __typename: "ModelBandMemberRequestConnection",
    items:  Array< {
      __typename: "BandMemberRequest",
      id: string,
      userId?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: BandMemberRequestStatus,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      creatorId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandMemberRequestsByUserAndBandQueryVariables = {
  userId: string,
  bandId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandMemberRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandMemberRequestsByUserAndBandQuery = {
  bandMemberRequestsByUserAndBand?:  {
    __typename: "ModelBandMemberRequestConnection",
    items:  Array< {
      __typename: "BandMemberRequest",
      id: string,
      userId?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: BandMemberRequestStatus,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      creatorId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandMemberRequestsByUserAndStatusQueryVariables = {
  userId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandMemberRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandMemberRequestsByUserAndStatusQuery = {
  bandMemberRequestsByUserAndStatus?:  {
    __typename: "ModelBandMemberRequestConnection",
    items:  Array< {
      __typename: "BandMemberRequest",
      id: string,
      userId?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: BandMemberRequestStatus,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      creatorId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandMemberRequestsByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandMemberRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandMemberRequestsByBandQuery = {
  bandMemberRequestsByBand?:  {
    __typename: "ModelBandMemberRequestConnection",
    items:  Array< {
      __typename: "BandMemberRequest",
      id: string,
      userId?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: BandMemberRequestStatus,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      creatorId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandPostQueryVariables = {
  id: string,
};

export type GetBandPostQuery = {
  getBandPost?:  {
    __typename: "BandPost",
    id: string,
    postImageUrl?: string | null,
    postImageUrls?: Array< string | null > | null,
    content?: string | null,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandPostCheers?:  {
      __typename: "ModelBandPostCheerConnection",
      items:  Array< {
        __typename: "BandPostCheer",
        id: string,
        bandPostId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    isCheered?: boolean | null,
    numCheers?: number | null,
    bandGroup?: string | null,
    bandPostTimelines?:  {
      __typename: "ModelBandPostTimelineConnection",
      items:  Array< {
        __typename: "BandPostTimeline",
        id: string,
        userId: string,
        bandId: string,
        bandPostId: string,
        createdAt: string,
        status: BandPostTimelineStatus,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    userId?: string | null,
  } | null,
};

export type ListBandPostsQueryVariables = {
  filter?: ModelBandPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandPostsQuery = {
  listBandPosts?:  {
    __typename: "ModelBandPostConnection",
    items:  Array< {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostsByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostsByBandQuery = {
  bandPostsByBand?:  {
    __typename: "ModelBandPostConnection",
    items:  Array< {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostsByBandAndDateQueryVariables = {
  bandId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostsByBandAndDateQuery = {
  bandPostsByBandAndDate?:  {
    __typename: "ModelBandPostConnection",
    items:  Array< {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostsByCreatorQueryVariables = {
  creatorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostsByCreatorQuery = {
  bandPostsByCreator?:  {
    __typename: "ModelBandPostConnection",
    items:  Array< {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandPostTimelineQueryVariables = {
  id: string,
};

export type GetBandPostTimelineQuery = {
  getBandPostTimeline?:  {
    __typename: "BandPostTimeline",
    id: string,
    userId: string,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandPostId: string,
    bandPost:  {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    },
    createdAt: string,
    status: BandPostTimelineStatus,
    updatedAt: string,
  } | null,
};

export type ListBandPostTimelinesQueryVariables = {
  filter?: ModelBandPostTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandPostTimelinesQuery = {
  listBandPostTimelines?:  {
    __typename: "ModelBandPostTimelineConnection",
    items:  Array< {
      __typename: "BandPostTimeline",
      id: string,
      userId: string,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandPostId: string,
      bandPost:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      },
      createdAt: string,
      status: BandPostTimelineStatus,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostTimelinesByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostTimelinesByUserQuery = {
  bandPostTimelinesByUser?:  {
    __typename: "ModelBandPostTimelineConnection",
    items:  Array< {
      __typename: "BandPostTimeline",
      id: string,
      userId: string,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandPostId: string,
      bandPost:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      },
      createdAt: string,
      status: BandPostTimelineStatus,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostTimelinesByUserAndStatusQueryVariables = {
  userId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostTimelinesByUserAndStatusQuery = {
  bandPostTimelinesByUserAndStatus?:  {
    __typename: "ModelBandPostTimelineConnection",
    items:  Array< {
      __typename: "BandPostTimeline",
      id: string,
      userId: string,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandPostId: string,
      bandPost:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      },
      createdAt: string,
      status: BandPostTimelineStatus,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostTimelinesByUserAndBandQueryVariables = {
  userId: string,
  bandId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostTimelinesByUserAndBandQuery = {
  bandPostTimelinesByUserAndBand?:  {
    __typename: "ModelBandPostTimelineConnection",
    items:  Array< {
      __typename: "BandPostTimeline",
      id: string,
      userId: string,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandPostId: string,
      bandPost:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      },
      createdAt: string,
      status: BandPostTimelineStatus,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostTimelinesByUserAndStatusAndTimeQueryVariables = {
  userId: string,
  statusCreatedAt?: ModelBandPostTimelineByUserAndStatusAndTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostTimelinesByUserAndStatusAndTimeQuery = {
  bandPostTimelinesByUserAndStatusAndTime?:  {
    __typename: "ModelBandPostTimelineConnection",
    items:  Array< {
      __typename: "BandPostTimeline",
      id: string,
      userId: string,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandPostId: string,
      bandPost:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      },
      createdAt: string,
      status: BandPostTimelineStatus,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostTimelinesByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostTimelinesByBandQuery = {
  bandPostTimelinesByBand?:  {
    __typename: "ModelBandPostTimelineConnection",
    items:  Array< {
      __typename: "BandPostTimeline",
      id: string,
      userId: string,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandPostId: string,
      bandPost:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      },
      createdAt: string,
      status: BandPostTimelineStatus,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostTimelinesByBandPostQueryVariables = {
  bandPostId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostTimelinesByBandPostQuery = {
  bandPostTimelinesByBandPost?:  {
    __typename: "ModelBandPostTimelineConnection",
    items:  Array< {
      __typename: "BandPostTimeline",
      id: string,
      userId: string,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandPostId: string,
      bandPost:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      },
      createdAt: string,
      status: BandPostTimelineStatus,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandPostCheerQueryVariables = {
  id: string,
};

export type GetBandPostCheerQuery = {
  getBandPostCheer?:  {
    __typename: "BandPostCheer",
    id: string,
    bandPostId: string,
    bandPost?:  {
      __typename: "BandPost",
      id: string,
      postImageUrl?: string | null,
      postImageUrls?: Array< string | null > | null,
      content?: string | null,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      isCheered?: boolean | null,
      numCheers?: number | null,
      bandGroup?: string | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      userId?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBandPostCheersQueryVariables = {
  filter?: ModelBandPostCheerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandPostCheersQuery = {
  listBandPostCheers?:  {
    __typename: "ModelBandPostCheerConnection",
    items:  Array< {
      __typename: "BandPostCheer",
      id: string,
      bandPostId: string,
      bandPost?:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostCheersByBandPostQueryVariables = {
  bandPostId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostCheerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostCheersByBandPostQuery = {
  bandPostCheersByBandPost?:  {
    __typename: "ModelBandPostCheerConnection",
    items:  Array< {
      __typename: "BandPostCheer",
      id: string,
      bandPostId: string,
      bandPost?:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostCheersByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostCheerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostCheersByUserQuery = {
  bandPostCheersByUser?:  {
    __typename: "ModelBandPostCheerConnection",
    items:  Array< {
      __typename: "BandPostCheer",
      id: string,
      bandPostId: string,
      bandPost?:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandPostCheersByUserAndBandPostQueryVariables = {
  userId: string,
  bandPostId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandPostCheerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandPostCheersByUserAndBandPostQuery = {
  bandPostCheersByUserAndBandPost?:  {
    __typename: "ModelBandPostCheerConnection",
    items:  Array< {
      __typename: "BandPostCheer",
      id: string,
      bandPostId: string,
      bandPost?:  {
        __typename: "BandPost",
        id: string,
        postImageUrl?: string | null,
        postImageUrls?: Array< string | null > | null,
        content?: string | null,
        bandId: string,
        creatorId?: string | null,
        createdAt: string,
        isCheered?: boolean | null,
        numCheers?: number | null,
        bandGroup?: string | null,
        updatedAt: string,
        userId?: string | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSongQueryVariables = {
  id: string,
};

export type GetSongQuery = {
  getSong?:  {
    __typename: "Song",
    id?: string | null,
    name?: string | null,
    albumName?: string | null,
    durationSeconds?: number | null,
    songPictureUrl?: string | null,
    order?: number | null,
    songUrl?: string | null,
    status?: SongStatus | null,
    bandId?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSongsQueryVariables = {
  filter?: ModelSongFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSongsQuery = {
  listSongs?:  {
    __typename: "ModelSongConnection",
    items:  Array< {
      __typename: "Song",
      id?: string | null,
      name?: string | null,
      albumName?: string | null,
      durationSeconds?: number | null,
      songPictureUrl?: string | null,
      order?: number | null,
      songUrl?: string | null,
      status?: SongStatus | null,
      bandId?: string | null,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SongsByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSongFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SongsByBandQuery = {
  songsByBand?:  {
    __typename: "ModelSongConnection",
    items:  Array< {
      __typename: "Song",
      id?: string | null,
      name?: string | null,
      albumName?: string | null,
      durationSeconds?: number | null,
      songPictureUrl?: string | null,
      order?: number | null,
      songUrl?: string | null,
      status?: SongStatus | null,
      bandId?: string | null,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SongsByBandAndStatusQueryVariables = {
  bandId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSongFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SongsByBandAndStatusQuery = {
  songsByBandAndStatus?:  {
    __typename: "ModelSongConnection",
    items:  Array< {
      __typename: "Song",
      id?: string | null,
      name?: string | null,
      albumName?: string | null,
      durationSeconds?: number | null,
      songPictureUrl?: string | null,
      order?: number | null,
      songUrl?: string | null,
      status?: SongStatus | null,
      bandId?: string | null,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShowQueryVariables = {
  id: string,
};

export type GetShowQuery = {
  getShow?:  {
    __typename: "Show",
    id: string,
    name: string,
    description?: string | null,
    date: string,
    dateTimeStamp?: number | null,
    venueName?: string | null,
    venueAddress?: string | null,
    venueCity?: string | null,
    venueState?: string | null,
    venueZipcode?: string | null,
    showPictureUrl?: string | null,
    imGoing?: boolean | null,
    goingCount?: number | null,
    bandShows?:  {
      __typename: "ModelBandShowConnection",
      items:  Array< {
        __typename: "BandShow",
        id: string,
        date: string,
        bandId: string,
        showId: string,
        status: BandShowStatus,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    showGoings?:  {
      __typename: "ModelShowGoingConnection",
      items:  Array< {
        __typename: "ShowGoing",
        id: string,
        userId: string,
        showId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    openingStatus?: ShowOpeningStatus | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    venueId?: string | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      address: string,
      city: string,
      createdAt: string,
      icon?: string | null,
      profileImageUrl?: string | null,
      foursquareId: string,
      latitude: number,
      longitude: number,
      name: string,
      state: string,
      status?: VenueStatus | null,
      updatedAt: string,
      isBlocked?: boolean | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      shows?:  {
        __typename: "ModelShowConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    creatorId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListShowsQueryVariables = {
  filter?: ModelShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShowsQuery = {
  listShows?:  {
    __typename: "ModelShowConnection",
    items:  Array< {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShowsByVenueQueryVariables = {
  venueId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShowsByVenueQuery = {
  showsByVenue?:  {
    __typename: "ModelShowConnection",
    items:  Array< {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShowsByVenueAndDateQueryVariables = {
  venueId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShowsByVenueAndDateQuery = {
  showsByVenueAndDate?:  {
    __typename: "ModelShowConnection",
    items:  Array< {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShowsByCreatorQueryVariables = {
  creatorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShowsByCreatorQuery = {
  showsByCreator?:  {
    __typename: "ModelShowConnection",
    items:  Array< {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandShowQueryVariables = {
  id: string,
};

export type GetBandShowQuery = {
  getBandShow?:  {
    __typename: "BandShow",
    id: string,
    date: string,
    bandId: string,
    showId: string,
    status: BandShowStatus,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    show?:  {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBandShowsQueryVariables = {
  filter?: ModelBandShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandShowsQuery = {
  listBandShows?:  {
    __typename: "ModelBandShowConnection",
    items:  Array< {
      __typename: "BandShow",
      id: string,
      date: string,
      bandId: string,
      showId: string,
      status: BandShowStatus,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandShowsByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandShowsByBandQuery = {
  bandShowsByBand?:  {
    __typename: "ModelBandShowConnection",
    items:  Array< {
      __typename: "BandShow",
      id: string,
      date: string,
      bandId: string,
      showId: string,
      status: BandShowStatus,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandShowsByBandAndDateQueryVariables = {
  bandId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandShowsByBandAndDateQuery = {
  bandShowsByBandAndDate?:  {
    __typename: "ModelBandShowConnection",
    items:  Array< {
      __typename: "BandShow",
      id: string,
      date: string,
      bandId: string,
      showId: string,
      status: BandShowStatus,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandShowsByBandAndStatusQueryVariables = {
  bandId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandShowsByBandAndStatusQuery = {
  bandShowsByBandAndStatus?:  {
    __typename: "ModelBandShowConnection",
    items:  Array< {
      __typename: "BandShow",
      id: string,
      date: string,
      bandId: string,
      showId: string,
      status: BandShowStatus,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandShowsByBandAndShowQueryVariables = {
  bandId: string,
  showId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandShowsByBandAndShowQuery = {
  bandShowsByBandAndShow?:  {
    __typename: "ModelBandShowConnection",
    items:  Array< {
      __typename: "BandShow",
      id: string,
      date: string,
      bandId: string,
      showId: string,
      status: BandShowStatus,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandShowsByShowQueryVariables = {
  showId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandShowsByShowQuery = {
  bandShowsByShow?:  {
    __typename: "ModelBandShowConnection",
    items:  Array< {
      __typename: "BandShow",
      id: string,
      date: string,
      bandId: string,
      showId: string,
      status: BandShowStatus,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandShowsByShowAndDateQueryVariables = {
  showId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandShowsByShowAndDateQuery = {
  bandShowsByShowAndDate?:  {
    __typename: "ModelBandShowConnection",
    items:  Array< {
      __typename: "BandShow",
      id: string,
      date: string,
      bandId: string,
      showId: string,
      status: BandShowStatus,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandShowsByShowAndStatusQueryVariables = {
  showId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandShowsByShowAndStatusQuery = {
  bandShowsByShowAndStatus?:  {
    __typename: "ModelBandShowConnection",
    items:  Array< {
      __typename: "BandShow",
      id: string,
      date: string,
      bandId: string,
      showId: string,
      status: BandShowStatus,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandShowsByBandGroupQueryVariables = {
  bandGroup: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandShowsByBandGroupQuery = {
  bandShowsByBandGroup?:  {
    __typename: "ModelBandShowConnection",
    items:  Array< {
      __typename: "BandShow",
      id: string,
      date: string,
      bandId: string,
      showId: string,
      status: BandShowStatus,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandShowsByBandGroupAndStatusQueryVariables = {
  bandGroup: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandShowsByBandGroupAndStatusQuery = {
  bandShowsByBandGroupAndStatus?:  {
    __typename: "ModelBandShowConnection",
    items:  Array< {
      __typename: "BandShow",
      id: string,
      date: string,
      bandId: string,
      showId: string,
      status: BandShowStatus,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVenueQueryVariables = {
  id: string,
};

export type GetVenueQuery = {
  getVenue?:  {
    __typename: "Venue",
    id: string,
    address: string,
    city: string,
    createdAt: string,
    icon?: string | null,
    profileImageUrl?: string | null,
    foursquareId: string,
    latitude: number,
    longitude: number,
    name: string,
    state: string,
    status?: VenueStatus | null,
    updatedAt: string,
    isBlocked?: boolean | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    shows?:  {
      __typename: "ModelShowConnection",
      items:  Array< {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListVenuesQueryVariables = {
  filter?: ModelVenueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVenuesQuery = {
  listVenues?:  {
    __typename: "ModelVenueConnection",
    items:  Array< {
      __typename: "Venue",
      id: string,
      address: string,
      city: string,
      createdAt: string,
      icon?: string | null,
      profileImageUrl?: string | null,
      foursquareId: string,
      latitude: number,
      longitude: number,
      name: string,
      state: string,
      status?: VenueStatus | null,
      updatedAt: string,
      isBlocked?: boolean | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      shows?:  {
        __typename: "ModelShowConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VenuesByFoursquareIdQueryVariables = {
  foursquareId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVenueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VenuesByFoursquareIdQuery = {
  venuesByFoursquareId?:  {
    __typename: "ModelVenueConnection",
    items:  Array< {
      __typename: "Venue",
      id: string,
      address: string,
      city: string,
      createdAt: string,
      icon?: string | null,
      profileImageUrl?: string | null,
      foursquareId: string,
      latitude: number,
      longitude: number,
      name: string,
      state: string,
      status?: VenueStatus | null,
      updatedAt: string,
      isBlocked?: boolean | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      shows?:  {
        __typename: "ModelShowConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandFanQueryVariables = {
  id: string,
};

export type GetBandFanQuery = {
  getBandFan?:  {
    __typename: "BandFan",
    id: string,
    bandId: string,
    userId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBandFansQueryVariables = {
  filter?: ModelBandFanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandFansQuery = {
  listBandFans?:  {
    __typename: "ModelBandFanConnection",
    items:  Array< {
      __typename: "BandFan",
      id: string,
      bandId: string,
      userId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandFansByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandFanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandFansByBandQuery = {
  bandFansByBand?:  {
    __typename: "ModelBandFanConnection",
    items:  Array< {
      __typename: "BandFan",
      id: string,
      bandId: string,
      userId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandFansByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandFanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandFansByUserQuery = {
  bandFansByUser?:  {
    __typename: "ModelBandFanConnection",
    items:  Array< {
      __typename: "BandFan",
      id: string,
      bandId: string,
      userId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandFansByUserAndBandQueryVariables = {
  userId: string,
  bandId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandFanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandFansByUserAndBandQuery = {
  bandFansByUserAndBand?:  {
    __typename: "ModelBandFanConnection",
    items:  Array< {
      __typename: "BandFan",
      id: string,
      bandId: string,
      userId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandFansByCreatorQueryVariables = {
  creatorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandFanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandFansByCreatorQuery = {
  bandFansByCreator?:  {
    __typename: "ModelBandFanConnection",
    items:  Array< {
      __typename: "BandFan",
      id: string,
      bandId: string,
      userId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandMapPointQueryVariables = {
  id: string,
};

export type GetBandMapPointQuery = {
  getBandMapPoint?:  {
    __typename: "BandMapPoint",
    id?: string | null,
    bandId?: string | null,
    name?: string | null,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    creatorId?: string | null,
    bandGroup?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    radius?: number | null,
    order?: number | null,
    dateStart?: string | null,
    dateEnd?: string | null,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBandMapPointsQueryVariables = {
  filter?: ModelBandMapPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandMapPointsQuery = {
  listBandMapPoints?:  {
    __typename: "ModelBandMapPointConnection",
    items:  Array< {
      __typename: "BandMapPoint",
      id?: string | null,
      bandId?: string | null,
      name?: string | null,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      radius?: number | null,
      order?: number | null,
      dateStart?: string | null,
      dateEnd?: string | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandMapPointsByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandMapPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandMapPointsByBandQuery = {
  bandMapPointsByBand?:  {
    __typename: "ModelBandMapPointConnection",
    items:  Array< {
      __typename: "BandMapPoint",
      id?: string | null,
      bandId?: string | null,
      name?: string | null,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      radius?: number | null,
      order?: number | null,
      dateStart?: string | null,
      dateEnd?: string | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandMapPointsByCreatorQueryVariables = {
  creatorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandMapPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandMapPointsByCreatorQuery = {
  bandMapPointsByCreator?:  {
    __typename: "ModelBandMapPointConnection",
    items:  Array< {
      __typename: "BandMapPoint",
      id?: string | null,
      bandId?: string | null,
      name?: string | null,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      radius?: number | null,
      order?: number | null,
      dateStart?: string | null,
      dateEnd?: string | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandTourQueryVariables = {
  id: string,
};

export type GetBandTourQuery = {
  getBandTour?:  {
    __typename: "BandTour",
    id: string,
    bandId: string,
    startDate: string,
    endDate: string,
    bandTourPoints?:  {
      __typename: "ModelBandTourPointConnection",
      items:  Array< {
        __typename: "BandTourPoint",
        id: string,
        bandId: string,
        bandTourId: string,
        latitude: number,
        longitude: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    name: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBandToursQueryVariables = {
  filter?: ModelBandTourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandToursQuery = {
  listBandTours?:  {
    __typename: "ModelBandTourConnection",
    items:  Array< {
      __typename: "BandTour",
      id: string,
      bandId: string,
      startDate: string,
      endDate: string,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      name: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandToursByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandTourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandToursByBandQuery = {
  bandToursByBand?:  {
    __typename: "ModelBandTourConnection",
    items:  Array< {
      __typename: "BandTour",
      id: string,
      bandId: string,
      startDate: string,
      endDate: string,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      name: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandTourPointQueryVariables = {
  id: string,
};

export type GetBandTourPointQuery = {
  getBandTourPoint?:  {
    __typename: "BandTourPoint",
    id: string,
    bandId: string,
    bandTourId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    bandTour?:  {
      __typename: "BandTour",
      id: string,
      bandId: string,
      startDate: string,
      endDate: string,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      name: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    latitude: number,
    longitude: number,
    _geoloc?:  {
      __typename: "GPS",
      lat?: number | null,
      lng?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBandTourPointsQueryVariables = {
  filter?: ModelBandTourPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandTourPointsQuery = {
  listBandTourPoints?:  {
    __typename: "ModelBandTourPointConnection",
    items:  Array< {
      __typename: "BandTourPoint",
      id: string,
      bandId: string,
      bandTourId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandTour?:  {
        __typename: "BandTour",
        id: string,
        bandId: string,
        startDate: string,
        endDate: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      latitude: number,
      longitude: number,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandTourPointsByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandTourPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandTourPointsByBandQuery = {
  bandTourPointsByBand?:  {
    __typename: "ModelBandTourPointConnection",
    items:  Array< {
      __typename: "BandTourPoint",
      id: string,
      bandId: string,
      bandTourId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandTour?:  {
        __typename: "BandTour",
        id: string,
        bandId: string,
        startDate: string,
        endDate: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      latitude: number,
      longitude: number,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandTourPointsByBandTourQueryVariables = {
  bandTourId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandTourPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandTourPointsByBandTourQuery = {
  bandTourPointsByBandTour?:  {
    __typename: "ModelBandTourPointConnection",
    items:  Array< {
      __typename: "BandTourPoint",
      id: string,
      bandId: string,
      bandTourId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      bandTour?:  {
        __typename: "BandTour",
        id: string,
        bandId: string,
        startDate: string,
        endDate: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      latitude: number,
      longitude: number,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShowGoingQueryVariables = {
  id: string,
};

export type GetShowGoingQuery = {
  getShowGoing?:  {
    __typename: "ShowGoing",
    id: string,
    userId: string,
    showId: string,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    show?:  {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListShowGoingsQueryVariables = {
  filter?: ModelShowGoingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShowGoingsQuery = {
  listShowGoings?:  {
    __typename: "ModelShowGoingConnection",
    items:  Array< {
      __typename: "ShowGoing",
      id: string,
      userId: string,
      showId: string,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShowGoingsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShowGoingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShowGoingsByUserQuery = {
  showGoingsByUser?:  {
    __typename: "ModelShowGoingConnection",
    items:  Array< {
      __typename: "ShowGoing",
      id: string,
      userId: string,
      showId: string,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShowGoingsByUserAndShowQueryVariables = {
  userId: string,
  showId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShowGoingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShowGoingsByUserAndShowQuery = {
  showGoingsByUserAndShow?:  {
    __typename: "ModelShowGoingConnection",
    items:  Array< {
      __typename: "ShowGoing",
      id: string,
      userId: string,
      showId: string,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShowGoingsByShowQueryVariables = {
  showId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShowGoingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShowGoingsByShowQuery = {
  showGoingsByShow?:  {
    __typename: "ModelShowGoingConnection",
    items:  Array< {
      __typename: "ShowGoing",
      id: string,
      userId: string,
      showId: string,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      show?:  {
        __typename: "Show",
        id: string,
        name: string,
        description?: string | null,
        date: string,
        dateTimeStamp?: number | null,
        venueName?: string | null,
        venueAddress?: string | null,
        venueCity?: string | null,
        venueState?: string | null,
        venueZipcode?: string | null,
        showPictureUrl?: string | null,
        imGoing?: boolean | null,
        goingCount?: number | null,
        openingStatus?: ShowOpeningStatus | null,
        venueId?: string | null,
        creatorId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFriendshipRequestQueryVariables = {
  id: string,
};

export type GetFriendshipRequestQuery = {
  getFriendshipRequest?:  {
    __typename: "FriendshipRequest",
    id: string,
    senderId: string,
    receiverId: string,
    sender?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    receiver?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: FriendshipRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFriendshipRequestsQueryVariables = {
  filter?: ModelFriendshipRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFriendshipRequestsQuery = {
  listFriendshipRequests?:  {
    __typename: "ModelFriendshipRequestConnection",
    items:  Array< {
      __typename: "FriendshipRequest",
      id: string,
      senderId: string,
      receiverId: string,
      sender?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      receiver?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: FriendshipRequestStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FriendshipRequestsBySenderQueryVariables = {
  senderId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendshipRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FriendshipRequestsBySenderQuery = {
  friendshipRequestsBySender?:  {
    __typename: "ModelFriendshipRequestConnection",
    items:  Array< {
      __typename: "FriendshipRequest",
      id: string,
      senderId: string,
      receiverId: string,
      sender?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      receiver?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: FriendshipRequestStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FriendshipRequestsBySenderAndStatusQueryVariables = {
  senderId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendshipRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FriendshipRequestsBySenderAndStatusQuery = {
  friendshipRequestsBySenderAndStatus?:  {
    __typename: "ModelFriendshipRequestConnection",
    items:  Array< {
      __typename: "FriendshipRequest",
      id: string,
      senderId: string,
      receiverId: string,
      sender?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      receiver?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: FriendshipRequestStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FriendshipRequestsBySenderAndReceiverQueryVariables = {
  senderId: string,
  receiverId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendshipRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FriendshipRequestsBySenderAndReceiverQuery = {
  friendshipRequestsBySenderAndReceiver?:  {
    __typename: "ModelFriendshipRequestConnection",
    items:  Array< {
      __typename: "FriendshipRequest",
      id: string,
      senderId: string,
      receiverId: string,
      sender?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      receiver?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: FriendshipRequestStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FriendshipRequestsByReceiverQueryVariables = {
  receiverId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendshipRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FriendshipRequestsByReceiverQuery = {
  friendshipRequestsByReceiver?:  {
    __typename: "ModelFriendshipRequestConnection",
    items:  Array< {
      __typename: "FriendshipRequest",
      id: string,
      senderId: string,
      receiverId: string,
      sender?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      receiver?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: FriendshipRequestStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FriendshipRequestsByReceiverAndStatusQueryVariables = {
  receiverId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendshipRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FriendshipRequestsByReceiverAndStatusQuery = {
  friendshipRequestsByReceiverAndStatus?:  {
    __typename: "ModelFriendshipRequestConnection",
    items:  Array< {
      __typename: "FriendshipRequest",
      id: string,
      senderId: string,
      receiverId: string,
      sender?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      receiver?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      status: FriendshipRequestStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFriendshipQueryVariables = {
  id: string,
};

export type GetFriendshipQuery = {
  getFriendship?:  {
    __typename: "Friendship",
    id: string,
    userId: string,
    friendId: string,
    isHidden?: boolean | null,
    user?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    friend?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFriendshipsQueryVariables = {
  filter?: ModelFriendshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFriendshipsQuery = {
  listFriendships?:  {
    __typename: "ModelFriendshipConnection",
    items:  Array< {
      __typename: "Friendship",
      id: string,
      userId: string,
      friendId: string,
      isHidden?: boolean | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      friend?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FriendshipsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FriendshipsByUserQuery = {
  friendshipsByUser?:  {
    __typename: "ModelFriendshipConnection",
    items:  Array< {
      __typename: "Friendship",
      id: string,
      userId: string,
      friendId: string,
      isHidden?: boolean | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      friend?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FriendshipsByUserAndFriendQueryVariables = {
  userId: string,
  friendId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FriendshipsByUserAndFriendQuery = {
  friendshipsByUserAndFriend?:  {
    __typename: "ModelFriendshipConnection",
    items:  Array< {
      __typename: "Friendship",
      id: string,
      userId: string,
      friendId: string,
      isHidden?: boolean | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      friend?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FriendshipsByFriendQueryVariables = {
  friendId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FriendshipsByFriendQuery = {
  friendshipsByFriend?:  {
    __typename: "ModelFriendshipConnection",
    items:  Array< {
      __typename: "Friendship",
      id: string,
      userId: string,
      friendId: string,
      isHidden?: boolean | null,
      user?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      friend?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBandHighlightQueryVariables = {
  id: string,
};

export type GetBandHighlightQuery = {
  getBandHighlight?:  {
    __typename: "BandHighlight",
    id: string,
    bandId: string,
    band?:  {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdById: string,
    creator?:  {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBandHighlightsQueryVariables = {
  filter?: ModelBandHighlightFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBandHighlightsQuery = {
  listBandHighlights?:  {
    __typename: "ModelBandHighlightConnection",
    items:  Array< {
      __typename: "BandHighlight",
      id: string,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdById: string,
      creator?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandHighlightsByBandQueryVariables = {
  bandId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandHighlightFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandHighlightsByBandQuery = {
  bandHighlightsByBand?:  {
    __typename: "ModelBandHighlightConnection",
    items:  Array< {
      __typename: "BandHighlight",
      id: string,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdById: string,
      creator?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BandHighlightsByUserQueryVariables = {
  createdById: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBandHighlightFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BandHighlightsByUserQuery = {
  bandHighlightsByUser?:  {
    __typename: "ModelBandHighlightConnection",
    items:  Array< {
      __typename: "BandHighlight",
      id: string,
      bandId: string,
      band?:  {
        __typename: "Band",
        id: string,
        name: string,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        zipcode?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        fanCount?: number | null,
        slug?: string | null,
        status?: BandStatus | null,
        hasPendingBandShow?: boolean | null,
        showTradeStatus?: ShowTradeStatus | null,
        creatorId?: string | null,
        bandGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdById: string,
      creator?:  {
        __typename: "User",
        userId: string,
        firstName: string,
        lastName: string,
        username: string,
        lowerUsername?: string | null,
        profilePictureUrl?: string | null,
        profileBannerUrl?: string | null,
        isFriend?: boolean | null,
        fanCount?: number | null,
        nonce?: string | null,
        secretToken?: string | null,
        preferencesId?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchUsersQueryVariables = {
  query?: string | null,
  filters?: string | null,
  searchableAttrs?: Array< string | null > | null,
  typoTolerance?: boolean | null,
};

export type SearchUsersQuery = {
  searchUsers?:  {
    __typename: "FindableUserConnection",
    items?:  Array< {
      __typename: "User",
      userId: string,
      firstName: string,
      lastName: string,
      username: string,
      lowerUsername?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      bandPostCheers?:  {
        __typename: "ModelBandPostCheerConnection",
        nextToken?: string | null,
      } | null,
      bandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      isFriend?: boolean | null,
      friendships?:  {
        __typename: "ModelFriendshipConnection",
        nextToken?: string | null,
      } | null,
      sentFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      receivedFriendRequests?:  {
        __typename: "ModelFriendshipRequestConnection",
        nextToken?: string | null,
      } | null,
      fanCount?: number | null,
      bandHighlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      nonce?: string | null,
      secretToken?: string | null,
      preferencesId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        id?: string | null,
        userId?: string | null,
        wantsEmailNotifications?: boolean | null,
        wantsBandRequestEmailNotif?: boolean | null,
        wantsBandFriendEmailNotif?: boolean | null,
        wantsUserFollowEmailNotif?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type SearchBandsQueryVariables = {
  query?: string | null,
  filters?: string | null,
  searchableAttrs?: Array< string | null > | null,
  typoTolerance?: boolean | null,
  aroundLatLng?: string | null,
};

export type SearchBandsQuery = {
  searchBands?:  {
    __typename: "FindableBandConnection",
    items?:  Array< {
      __typename: "Band",
      id: string,
      name: string,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      zipcode?: string | null,
      profilePictureUrl?: string | null,
      profileBannerUrl?: string | null,
      fanCount?: number | null,
      slug?: string | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      status?: BandStatus | null,
      songs?:  {
        __typename: "ModelSongConnection",
        nextToken?: string | null,
      } | null,
      pendingBandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      bandPosts?:  {
        __typename: "ModelBandPostConnection",
        nextToken?: string | null,
      } | null,
      bandPostTimelines?:  {
        __typename: "ModelBandPostTimelineConnection",
        nextToken?: string | null,
      } | null,
      highlights?:  {
        __typename: "ModelBandHighlightConnection",
        nextToken?: string | null,
      } | null,
      bandFans?:  {
        __typename: "ModelBandFanConnection",
        nextToken?: string | null,
      } | null,
      bandMembers?:  {
        __typename: "ModelBandMemberConnection",
        nextToken?: string | null,
      } | null,
      BandMemberRequests?:  {
        __typename: "ModelBandMemberRequestConnection",
        nextToken?: string | null,
      } | null,
      hasPendingBandShow?: boolean | null,
      showTradeStatus?: ShowTradeStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      bandMapPoints?:  {
        __typename: "ModelBandMapPointConnection",
        nextToken?: string | null,
      } | null,
      bandTours?:  {
        __typename: "ModelBandTourConnection",
        nextToken?: string | null,
      } | null,
      bandTourPoints?:  {
        __typename: "ModelBandTourPointConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      bandGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type SearchShowsQueryVariables = {
  query?: string | null,
  filters?: string | null,
  searchableAttrs?: Array< string | null > | null,
  typoTolerance?: boolean | null,
  aroundLatLng?: string | null,
};

export type SearchShowsQuery = {
  searchShows?:  {
    __typename: "FindableShowConnection",
    items?:  Array< {
      __typename: "Show",
      id: string,
      name: string,
      description?: string | null,
      date: string,
      dateTimeStamp?: number | null,
      venueName?: string | null,
      venueAddress?: string | null,
      venueCity?: string | null,
      venueState?: string | null,
      venueZipcode?: string | null,
      showPictureUrl?: string | null,
      imGoing?: boolean | null,
      goingCount?: number | null,
      bandShows?:  {
        __typename: "ModelBandShowConnection",
        nextToken?: string | null,
      } | null,
      showGoings?:  {
        __typename: "ModelShowGoingConnection",
        nextToken?: string | null,
      } | null,
      openingStatus?: ShowOpeningStatus | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      venueId?: string | null,
      venue?:  {
        __typename: "Venue",
        id: string,
        address: string,
        city: string,
        createdAt: string,
        icon?: string | null,
        profileImageUrl?: string | null,
        foursquareId: string,
        latitude: number,
        longitude: number,
        name: string,
        state: string,
        status?: VenueStatus | null,
        updatedAt: string,
        isBlocked?: boolean | null,
      } | null,
      creatorId: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type SearchVenuesQueryVariables = {
  query?: string | null,
  filters?: string | null,
  searchableAttrs?: Array< string | null > | null,
  typoTolerance?: boolean | null,
  aroundLatLng?: string | null,
};

export type SearchVenuesQuery = {
  searchVenues?:  {
    __typename: "FindableVenueConnection",
    items?:  Array< {
      __typename: "Venue",
      id: string,
      address: string,
      city: string,
      createdAt: string,
      icon?: string | null,
      profileImageUrl?: string | null,
      foursquareId: string,
      latitude: number,
      longitude: number,
      name: string,
      state: string,
      status?: VenueStatus | null,
      updatedAt: string,
      isBlocked?: boolean | null,
      _geoloc?:  {
        __typename: "GPS",
        lat?: number | null,
        lng?: number | null,
      } | null,
      shows?:  {
        __typename: "ModelShowConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};
