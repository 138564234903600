/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://7zn4ngwevjhvvdmxpyoztrfieu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-wng3xe6qf5dg3hbgyzdwagdzly",
    "aws_cognito_identity_pool_id": "us-east-1:7c9c4b00-a844-4e89-8d99-84430bf17f43",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tuv88KIaV",
    "aws_user_pools_web_client_id": "62pnn18ps8kfmmi4s28m7j7svj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tothemusic-app-storage104604-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
