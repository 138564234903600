import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { User } from "src/API";
import { isValidUsername } from "src/utils/username";
import * as mutations from "./mutations";
import { searchUsersByUsername } from "../Profile/backend";

type CreateUserProps = {
  userId: string;
  firstName: string;
  lastName: string;
  username: string;
  profilePictureUrl?: string | null;
};

type CreateUserResponse = {
  createUser: User;
};

export const createUser = async ({
  userId,
  firstName,
  lastName,
  username,
  profilePictureUrl,
}: CreateUserProps): Promise<User | null> => {
  const existingUsers = await searchUsersByUsername(username);

  const valid = existingUsers.find(
    (user) => user.username.toLowerCase() === username.toLowerCase(),
  )
    ? false
    : true;

  if (!isValidUsername(username)) {
    return null;
  }

  if (!valid) {
    return null;
  }

  const result = (await API.graphql(
    graphqlOperation(mutations.createUser, {
      input: {
        id: userId,
        userId,
        firstName,
        lastName,
        username,
        profilePictureUrl,
      },
    }),
  )) as GraphQLResult<CreateUserResponse>;

  if (result.errors) {
    console.log("[ERROR] error creating user", result.errors);
  }
  if (result.data?.createUser) {
    return result.data.createUser;
  }
  return null;
};
