export const bandMembersByUser = /* GraphQL */ `
  query BandMembersByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBandMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandMembersByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instrument
        creatorId
        bandId
        userId
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
          profileBannerUrl
        }
      }
      nextToken
    }
  }
`;

export const getBand = /* GraphQL */ `
  query GetBand($id: ID!) {
    getBand(id: $id) {
      id
      name
      description
      city
      state
      slug
      zipcode
      fanCount
      profilePictureUrl
      profileBannerUrl
    }
  }
`;

export const bandMembersByBand = /* GraphQL */ `
  query BandMembersByBand(
    $bandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBandMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandMembersByBand(
      bandId: $bandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instrument
        userId
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
        }
        user {
          id
          firstName
          lastName
          profilePictureUrl
          createdAt
        }
      }
      nextToken
    }
  }
`;

export const searchUsers = /* GraphQL */ `
  query SearchUsers($query: String) {
    searchUsers(query: $query) {
      items {
        id
        firstName
        lastName
        profilePictureUrl
        username
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

export const searchBands = /* GraphQL */ `
  query SearchBands($query: String) {
    searchBands(query: $query) {
      items {
        id
        name
        description
        slug
        city
        state
        zipcode
        profilePictureUrl
      }
      nextToken
      total
    }
  }
`;

export const listBands = /* GraphQL */ `
  query ListBands(
    $filter: ModelBandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        city
        state
        zipcode
        profilePictureUrl
      }
      nextToken
    }
  }
`;

export const songsByBand = /* GraphQL */ `
  query SongsByBand(
    $bandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSongFilterInput
    $limit: Int
    $nextToken: String
  ) {
    songsByBand(
      bandId: $bandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        albumName
        durationSeconds
        songPictureUrl
        songUrl
        order
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const songsByBandAndStatus = /* GraphQL */ `
  query SongsByBandAndStatus(
    $bandId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSongFilterInput
    $limit: Int
    $nextToken: String
  ) {
    songsByBandAndStatus(
      bandId: $bandId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        albumName
        durationSeconds
        songPictureUrl
        order
        songUrl
        status
        bandId
        updatedAt
      }
      nextToken
    }
  }
`;

export const bandFansByUserAndBand = /* GraphQL */ `
  query BandFansByUserAndBand(
    $userId: ID!
    $bandId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBandFanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandFansByUserAndBand(
      userId: $userId
      bandId: $bandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bandId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const bandPostsByBand = /* GraphQL */ `
  query BandPostsByBand(
    $bandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBandPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandPostsByBand(
      bandId: $bandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postImageUrl
        postImageUrls
        content
        bandId
        numCheers
        isCheered
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const bandPostsByBandAndDate = /* GraphQL */ `
  query BandPostsByBandAndDate(
    $bandId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBandPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandPostsByBandAndDate(
      bandId: $bandId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postImageUrl
        postImageUrls
        numCheers
        content
        bandId
        isCheered
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
          profileBannerUrl
          fanCount
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const bandShowsByBandAndDate = /* GraphQL */ `
  query BandShowsByBandAndDate(
    $bandId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBandShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandShowsByBandAndDate(
      bandId: $bandId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        bandId
        showId
        status
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
          profileBannerUrl
          fanCount
          slug
          createdAt
          updatedAt
        }
        show {
          id
          name
          description
          date
          venue {
            id
            name
            address
            city
            state
          }
          venueName
          venueAddress
          venueCity
          venueState
          venueZipcode
          showPictureUrl
          venueId
          imGoing
          goingCount
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const showGoingsByUserAndShow = /* GraphQL */ `
  query ShowGoingsByUserAndShow(
    $userId: ID!
    $showId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowGoingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showGoingsByUserAndShow(
      userId: $userId
      showId: $showId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        showId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const bandMemberRequestsByBand = /* GraphQL */ `
  query BandMemberRequestsByBand(
    $bandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBandMemberRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandMemberRequestsByBand(
      bandId: $bandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        bandId
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listBandHighlights = /* GraphQL */ `
  query ListBandHighlights(
    $filter: ModelBandHighlightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBandHighlights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bandId
        band {
          id
          name
          description
          city
          state
          zipcode
          profilePictureUrl
          profileBannerUrl
          fanCount
          slug
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const bandHighlightsByBand = /* GraphQL */ `
  query BandHighlightsByBand(
    $bandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBandHighlightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandHighlightsByBand(
      bandId: $bandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bandId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const bandMapPointsByBand = /* GraphQL */ `
  query BandMapPointsByBand(
    $bandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBandMapPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bandMapPointsByBand(
      bandId: $bandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bandId
        name
        radius
        latitude
        longitude
        order
        creatorId
        _geoloc {
          lat
          lng
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
