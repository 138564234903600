import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Band } from "src/API";
import { selectRandom } from "src/utils/random";
import { getBandsPublic } from "../Bands/backend";

const bgCSS = {
  borderBottom: "1px solid #eee",
};

const useHighlightedBands = () => {
  return useQuery(
    ["band", "public"],
    async () => {
      const res = await getBandsPublic();
      const random = res ? selectRandom(res, 5) : [];
      return random;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const UnAuthedHome = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const { data: bands } = useHighlightedBands();

  const renderBandCard = (band: Band) => {
    return (
      <Grid item xs={12} sm={6} md={4} key={`band-card-${band.id}`}>
        <Card sx={{ minWidth: 275 }}>
          <CardActionArea onClick={() => navigate(`/bands/${band.id}`)}>
            {band.profilePictureUrl && (
              <CardMedia
                component="img"
                height="194"
                image={band.profilePictureUrl}
              />
            )}
            <CardContent>
              <Typography fontWeight="bold">{band.name}</Typography>
              <Typography>
                {band.city}, {band.state}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };
  return (
    <Container maxWidth="md">
      <Grid container spacing={2} mb={10}>
        <Grid item xs={12} pb={3} sx={bgCSS}>
          <Typography
            variant={isMobile ? "h5" : "h3"}
            sx={{
              textAlign: { xs: "center", md: "left" },
              fontWeight: { xs: "bold", sm: "inherit" },
              mb: { xs: 2, sm: 0 },
            }}>
            Connecting bands and fans
          </Typography>
          <Typography variant="subtitle1">
            ToTheMusic lets bands connect with other bands to trade shows. Fans
            can follow their favorite bands and get the latest updates.
          </Typography>

          <Grid container gap={1}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" sx={{ mt: 3 }}>
                For Bands:
              </Typography>
              <ul>
                <li>
                  <Typography>
                    Connect with bands in cities where you want to play
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Trade shows with other bands. "Let us open for you in your
                    town and you can open for us in ours!"
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="h6" sx={{ mt: 3 }}>
                For Fans:
              </Typography>
              <ul>
                <li>
                  <Typography>Follow your favorite bands</Typography>
                </li>
                <li>
                  <Typography>
                    See what shows your friends are going to
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Typography variant="h6">
            Sign up now to add your band and start trading shows!
          </Typography>
        </Grid>
        {bands && bands.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ my: 3 }}>
              Popular Bands
            </Typography>
            <Grid container spacing={2}>
              {bands?.map((band) => renderBandCard(band))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
