import { Box, Container, SxProps, Typography } from "@mui/material";

type HeaderProps = {
  title: string;
  right?: React.ReactNode;
  sx?: SxProps;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
};

export const Header = ({ title, right, sx, maxWidth = "lg" }: HeaderProps) => {
  return (
    <Box sx={{ backgroundColor: "#000", color: "white" }}>
      <Container
        maxWidth={maxWidth}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          py: 3,
          ...(sx || {}),
        }}>
        <Typography variant="h4">{title}</Typography>
        {right}
      </Container>
    </Box>
  );
};
