import { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import * as awsConfig from "src/aws-exports";

const { aws_user_files_s3_bucket } = awsConfig.default;

const DEV = aws_user_files_s3_bucket
  ? aws_user_files_s3_bucket.indexOf("dev") > -1
  : false;

const DEVELOP = aws_user_files_s3_bucket
  ? aws_user_files_s3_bucket.indexOf("develop") > -1
  : false;

const STAGING = aws_user_files_s3_bucket
  ? aws_user_files_s3_bucket.indexOf("staging") > -1
  : false;

const DEV_CLOUD_PREFIX = "https://assets.dev.tothemusic.com/";
const DEVELOP_CLOUD_PREFIX = "https://assets.develop.tothemusic.com/";
const STAGING_CLOUD_PREFIX = "https://assets.staging.tothemusic.com/";
const PROD_CLOUD_PREFIX = "https://assets.tothemusic.com/";

let CLOUD_FORMATION_URL = PROD_CLOUD_PREFIX;
if (DEVELOP) {
  CLOUD_FORMATION_URL = DEVELOP_CLOUD_PREFIX;
} else if (DEV) {
  CLOUD_FORMATION_URL = DEV_CLOUD_PREFIX;
} else if (STAGING) {
  CLOUD_FORMATION_URL = STAGING_CLOUD_PREFIX;
}

export const getImageUrl = (path: string): string => {
  return `${CLOUD_FORMATION_URL}${path}`;
};

export const uploadToS3 = async (
  file: File,
  keyFolder: string,
): Promise<string | null> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const keyStart = keyFolder ? keyFolder : "misc";
    const uploadKey = `${keyStart}/${user.attributes.sub}-${uuidv4()}.jpeg`;
    const storageResp = (await Storage.put(uploadKey, file, {
      contentType: "image/jpeg",
    })) as { key: string };

    const { key } = storageResp;

    return getImageUrl(key);
  } catch (err) {
    console.log("[ERROR] error uploading picture", err);
    return null;
  }
};
