import { respondToBandMemberRequest } from "../backend";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { BandMemberRequest } from "src/API";
import { useState } from "react";
import { refreshSession } from "src/utils/auth";
import { useNavigate } from "react-router-dom";

const BandMemberRequests = ({
  bandRequests,
  refetch,
  isFetching,
}: {
  bandRequests: BandMemberRequest[];
  isFetching: boolean;
  refetch: () => void;
}) => {
  const [isActing, setIsActing] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleAcceptRequest = async (bandRequest: BandMemberRequest) => {
    setIsActing(true);
    try {
      await respondToBandMemberRequest(bandRequest, "ACCEPT");
      refreshSession();
      refetch();
    } catch (e) {
      console.error(e);
    } finally {
      setIsActing(false);
    }
  };

  const handleRejectRequest = async (bandRequest: BandMemberRequest) => {
    setIsActing(true);
    try {
      await respondToBandMemberRequest(bandRequest, "REJECT");
      refetch();
    } catch (e) {
      console.error(e);
    } finally {
      setIsActing(false);
    }
  };
  if (isFetching) {
    return (
      <Container sx={{ my: 2 }}>
        <LinearProgress />
      </Container>
    );
  }
  if (!bandRequests || bandRequests.length === 0) {
    return (
      <Container>
        <Typography variant="h6">No band member requests</Typography>
      </Container>
    );
  }
  return (
    <Box sx={{ borderTop: "1px #f0f0f0 solid", mt: 3, pt: 1 }}>
      <Grid container spacing={1}>
        {bandRequests.map((bandRequest) => {
          const { band } = bandRequest;
          if (!band) {
            return null;
          }
          return (
            <Grid item xs={12} md={6} key={bandRequest.id}>
              <Card>
                <CardContent
                  onClick={() => navigate(`/bands/${bandRequest.bandId}`)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#fafafa",
                    },
                  }}>
                  <Typography>You've been invited to join the band</Typography>
                  {band.profilePictureUrl ? (
                    <img
                      src={band.profilePictureUrl}
                      alt={band.name}
                      style={{ width: 100, height: 100 }}
                    />
                  ) : null}
                  <Typography>{band.name}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    sx={{ display: "flex", flexGrow: 1 }}
                    onClick={() => navigate(`/bands/${bandRequest.bandId}`)}>
                    See Band
                  </Button>
                  <Divider sx={{ ml: 1 }} orientation="vertical" flexItem />
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isActing}
                    onClick={() => handleAcceptRequest(bandRequest)}>
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isActing}
                    onClick={() => handleRejectRequest(bandRequest)}>
                    Reject
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default BandMemberRequests;
