import { Check, Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NoUserPicture from "src/components/NoUserPicture";
import {
  acceptFriendshipRequest,
  rejectFriendshipRequest,
} from "src/containers/Profile/backend";
import { FriendshipRequest } from "src/API";

const FriendshipRequests = ({
  friendshipRequests,
  isFetching,
  refetch,
}: {
  friendshipRequests: FriendshipRequest[];
  isFetching: boolean;
  refetch: () => void;
}) => {
  const [responding, setResponding] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const navigate = useNavigate();

  const handleAccept = async (friendshipRequestId: string) => {
    try {
      const result = await acceptFriendshipRequest(friendshipRequestId);
      if (result) {
        refetch();
      }
    } catch (e) {
      console.log("[ERROR] error accepting band show request", e);
    }
  };
  const handleReject = async (friendshipRequestId: string) => {
    try {
      const result = await rejectFriendshipRequest(friendshipRequestId);
      if (result) {
        refetch();
      }
    } catch (e) {
      console.log("[ERROR] error rejecting band show request", e);
    }
  };

  if (isFetching) {
    return (
      <Container sx={{ my: 2 }}>
        <LinearProgress />
      </Container>
    );
  }
  return (
    <Box sx={{ borderTop: "1px #f0f0f0 solid", mt: 3, pt: 1 }}>
      <List sx={{ my: 1 }}>
        {friendshipRequests.map((friendshipRequest) => {
          const { sender } = friendshipRequest;
          if (!sender) {
            return null;
          }
          return (
            <ListItem
              key={`request-${friendshipRequest.id}`}
              alignItems="flex-start"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                borderBottom: "1px #f0f0f0 solid",
              }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ListItemAvatar
                  onClick={() => navigate(`/users/${sender.id}`)}
                  sx={{ cursor: "pointer" }}>
                  {sender.profilePictureUrl ? (
                    <Avatar src={sender.profilePictureUrl} />
                  ) : (
                    <NoUserPicture size={50} />
                  )}
                </ListItemAvatar>
                <ListItemText
                  onClick={() => navigate(`/users/${sender.id}`)}
                  sx={{ cursor: "pointer", pr: 1 }}
                  primary="New Friend Request"
                  secondary={`${sender.firstName} ${sender.lastName} has sent you a friend
                    request`}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: { xs: "100%", sm: "auto" },
                  justifyContent: { xs: "flex-end", sm: "center" },
                }}>
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    setResponding({
                      ...responding,
                      [friendshipRequest.id]: e.currentTarget,
                    });
                  }}>
                  Respond
                </Button>
                <Menu
                  open={Boolean(responding[friendshipRequest.id])}
                  onClose={() =>
                    setResponding({
                      ...responding,
                      [friendshipRequest.id]: null,
                    })
                  }
                  anchorEl={responding[friendshipRequest.id]}>
                  <MenuItem sx={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      startIcon={<Check />}
                      onClick={() => handleAccept(friendshipRequest.id)}>
                      Accept
                    </Button>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="error"
                      startIcon={<Close />}
                      onClick={() => handleReject(friendshipRequest.id)}
                      sx={{ mt: 1 }}>
                      Reject
                    </Button>
                  </MenuItem>
                </Menu>
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default FriendshipRequests;
