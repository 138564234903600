import { useState } from "react";

export const useSearch = (searchFn: (query: string) => Promise<any>) => {
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasSearched, setHasSearched] = useState(false);

  const search = async (query: string) => {
    try {
      setError(null);
      setIsSearching(true);
      setHasSearched(true);
      const result = await searchFn(query);
      return result;
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error searching via useSearch", e);
      setError(e.message);
    } finally {
      setIsSearching(false);
    }
  };
  return {
    hasSearched,
    isSearching,
    error,
    search,
  };
};
