import { Check, Close, Search } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  Alert,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Chip,
  Tabs,
  Tab,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { Band } from "src/API";
import NoBandPicture from "src/components/NoBandPicture";
import {
  getMyBandMembershipsForUser,
  searchBands,
} from "src/containers/Bands/backend";
import { useSearch } from "src/utils/search";
import { getBandShowsForShow, inviteBand } from "../backend";
import { useTimedMessage } from "src/utils/timedMessage";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "src/stores";
import TabPanel from "src/components/TabPanel";

const useMyBands = (id?: string | null) => {
  return useQuery(
    ["user", id, "bands"],
    async () => {
      if (!id) {
        return [];
      }
      const myBandMemberships = await getMyBandMembershipsForUser(id);
      return myBandMemberships;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBandShowsForShow = (id?: string) => {
  return useQuery(
    ["show", id, "bands"],
    async () => {
      if (!id) {
        return null;
      }
      const bandShows = await getBandShowsForShow(id);
      return bandShows;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const BandSearchDialog = ({
  showId,
  onClose,
  onRefresh,
  isOpen,
}: {
  isOpen: boolean;
  onClose: () => void;
  onRefresh: () => void;
  showId: string;
}) => {
  const [bandQuery, setBandQuery] = useState<string>("");
  const { id: userId } = useSelector((state: RootState) => state.user);

  const [tabValue, setTabValue] = useState<number>(0);

  const { data: myBandMemberships, isFetching: isFetchingMyBandMemberships } =
    useMyBands(userId);

  const {
    data: bandShows,
    isFetching: isFetchingBandShows,
    refetch: refetchBandShows,
  } = useBandShowsForShow(showId);

  const [searchResults, setSearchResults] = useState<Band[]>([]);

  const [isInviting, setIsInviting] = useState<boolean>(false);
  const {
    message: successMessage,
    setMessage: setSuccessMessage,
    clearMessage: clearSuccessMessage,
  } = useTimedMessage(5000);
  const {
    message: errorMessage,
    setMessage: setErrorMessage,
    clearMessage: clearErrorMessage,
  } = useTimedMessage(5000);

  const { hasSearched, isSearching, search, error } = useSearch(searchBands);

  const isBandInvited = (bandId: string) => {
    return bandShows?.some((bandShow) => bandShow.bandId === bandId);
  };

  const onClickSearch = async () => {
    try {
      const result = await search(bandQuery);
      setSearchResults(result);
    } catch (e) {
      console.log("[ERROR] error searching for band", e);
    }
  };
  const handleClickInviteBand = async (band: Band) => {
    try {
      setIsInviting(true);
      clearSuccessMessage();
      clearErrorMessage();
      const resultJson = await inviteBand({ showId, bandId: band.id });
      if (!resultJson) {
        setErrorMessage("No response from server");
      }
      // @ts-ignore
      const { success, message } = JSON.parse(resultJson) as {
        success: boolean;
        message: string;
      };
      if (success) {
        setSuccessMessage("Successfully invited band");
        refetchBandShows();
        onRefresh();
      }
      if (!success) {
        setErrorMessage(message);
      }
    } catch (e) {
      console.log("[ERROR] error inviting band", e);
    } finally {
      setIsInviting(false);
    }
  };
  const renderInviteButton = (band: Band) => {
    if (isFetchingBandShows) {
      return <CircularProgress />;
    }
    if (isBandInvited(band.id)) {
      return <Chip label="Invited" icon={<Check fontSize="small" />} />;
    }
    const isMyBand =
      myBandMemberships && myBandMemberships.some((b) => b.bandId === band.id);
    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <LoadingButton
          loading={isInviting}
          onClick={() => handleClickInviteBand(band)}
          variant="contained"
          color={isMyBand ? "secondary" : "primary"}
          sx={{ mt: { xs: 3, md: 0 } }}>
          {isMyBand ? "Add My Band" : "Invite to Show"}
        </LoadingButton>
        {isMyBand && (
          <Typography variant="body2">Your bands auto-accept</Typography>
        )}
      </Box>
    );
  };
  const renderBand = (band: Band) => {
    return (
      <Card key={band.id} sx={{ minWidth: { xs: 0, md: 600 } }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "inherit" },
            }}>
            {band.profilePictureUrl ? (
              <img
                src={band.profilePictureUrl}
                style={{ width: 75, height: 75 }}
                alt={band.name}
              />
            ) : (
              <NoBandPicture size={75} iconSize="2x" />
            )}
            <Box
              sx={{
                ml: { xs: 0, md: 1 },
                textAlign: { xs: "center", md: "left" },
              }}>
              <Typography fontWeight="bold">{band.name}</Typography>
              <Typography>
                {[band.city, band.state, band.zipcode]
                  .filter((x) => x)
                  .join(", ")}
                {!band.city && !band.state && !band.zipcode && "(No Location)"}
              </Typography>
            </Box>
          </Box>
          {renderInviteButton(band)}
        </CardContent>
      </Card>
    );
  };
  const renderSearchResults = () => {
    if (isSearching) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      );
    }
    if (!hasSearched) {
      return null;
    }
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }
    return <Box>{searchResults.map((band) => renderBand(band))}</Box>;
  };

  const renderMyBands = () => {
    if (isFetchingMyBandMemberships) {
      return <CircularProgress />;
    }
    if (!myBandMemberships) {
      return null;
    }
    return (
      <>
        {myBandMemberships.map((membership) => {
          const { band } = membership;
          if (!band) {
            return null;
          }

          return renderBand(band);
        })}
      </>
    );
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="md" scroll="paper">
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <Box>Add New Band</Box>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Tabs
          sx={{ mb: 2 }}
          value={tabValue}
          onChange={(_, newValue: number) => setTabValue(newValue)}>
          <Tab label="Search Bands" value={0} />
          <Tab label="My Bands" value={1} />
        </Tabs>
        {errorMessage && (
          <Alert variant="filled" severity="error" sx={{ my: 1 }}>
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert variant="filled" severity="success" sx={{ my: 1 }}>
            {successMessage}
          </Alert>
        )}
        <TabPanel value={tabValue} index={0}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onClickSearch();
              return false;
            }}>
            <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
              <InputLabel htmlFor="band-search-box">Search bands</InputLabel>
              <OutlinedInput
                id="band-search-box"
                value={bandQuery}
                type="search"
                onChange={(e) => {
                  if (e.target.value.length < 2) {
                    setSearchResults([]);
                  }
                  setBandQuery(e.target.value);
                }}
                endAdornment={
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    onClick={onClickSearch}>
                    <Search />
                  </Button>
                }
                label="Search bands"
              />
            </FormControl>
          </form>
          {renderSearchResults()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {renderMyBands()}
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default BandSearchDialog;
