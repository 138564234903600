import { Groups, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Header } from "src/components/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/stores";
import { searchBands } from "./backend";
import { Band } from "src/API";

export const Bands = () => {
  const [searchResult, setSearchResult] = useState<Band[] | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const searchQuery = searchParams.get("q");
  const [search, setSearch] = useState<string>(searchQuery || "");
  const [initialSearchDone, setInitialSearchDone] = useState(false);

  const { isAuthed } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();

  const onClickSearch = useCallback(async () => {
    try {
      setIsSearching(true);
      setSearchParams({ q: search });
      const result = await searchBands(search);
      setSearchResult(result);
    } catch (e) {
      console.log("[ERROR] error searching for band", e);
    } finally {
      setTimeout(() => {
        setIsSearching(false);
      }, 100);
    }
  }, [search, setSearchParams]);

  useEffect(() => {
    if (!initialSearchDone && searchQuery && searchQuery.length > 2) {
      onClickSearch();
      setInitialSearchDone(true);
    }
  }, [searchQuery, initialSearchDone, onClickSearch]);
  return (
    <div>
      <Header
        title="Bands"
        right={
          isAuthed ? (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Groups />}
              onClick={() => navigate("mine")}>
              My Bands
            </Button>
          ) : null
        }
      />
      <Container maxWidth="md">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClickSearch();
            return false;
          }}>
          <FormControl sx={{ my: 2 }} variant="outlined" fullWidth>
            <InputLabel htmlFor="band-search-box">Search bands</InputLabel>
            <OutlinedInput
              id="band-search-box"
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              endAdornment={
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={onClickSearch}>
                  <Search />
                </Button>
              }
              label="Search bands"
            />
          </FormControl>
        </form>
        {isSearching && (
          <Container>
            <LinearProgress sx={{ my: 2 }} />
          </Container>
        )}
        {!isSearching && (
          <Grid container spacing={1}>
            {searchResult?.map((band) => (
              <Grid item xs={12} sm={6} md={4} key={`band-${band.id}`}>
                <Card
                  onClick={() => navigate(`/bands/${band.id}`)}
                  sx={{
                    cursor: "pointer",
                    border: "2px transparent solid",
                    transition: "all 0.111s ease-in-out",
                    "&:hover": {
                      border: "2px solid rgba(0,0,0,.25)",
                      backgroundColor: "#fafafa",
                    },
                  }}>
                  {band.profilePictureUrl && (
                    <CardMedia
                      component="img"
                      src={band.profilePictureUrl}
                      height={200}
                    />
                  )}
                  <CardContent>
                    <Box>
                      <Typography variant="h6">{band.name}</Typography>
                    </Box>
                    {band.city && band.state && (
                      <Typography>
                        {band.city}, {band.state}{" "}
                        {band.zipcode ? `(${band.zipcode})` : ""}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        {!isSearching && searchResult && searchResult?.length === 0 && (
          <Container maxWidth="sm" sx={{ textAlign: "center" }}>
            <Typography variant="h6">No Results</Typography>
          </Container>
        )}
      </Container>
    </div>
  );
};
