import { HeartBroken, Search } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Venue } from "src/API";
import { Header } from "src/components/Header";
import VenuePicture from "src/components/VenuePicture";
import { useSearch } from "src/utils/search";
import { searchVenues } from "./backend";

export const Venues = () => {
  const [search, setSearch] = useState("");

  const [venues, setVenues] = useState<Venue[] | null>(null);
  const navigate = useNavigate();

  const {
    isSearching,
    error,
    search: searchFn,
  } = useSearch(async (query: string) => {
    const venues = await searchVenues(query);
    setVenues(venues);
  });

  const onClickSearch = () => {
    searchFn(search);
  };

  const renderVenues = () => {
    if (isSearching) {
      return <LinearProgress />;
    }
    if (!venues) {
      return null;
    }
    if (venues.length === 0) {
      return (
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="center">
          <Typography textAlign="center" variant="h6">
            No Venues Found
          </Typography>
          <HeartBroken fontSize="large" />
        </Stack>
      );
    }
    if (error) {
      return <Typography>{error}</Typography>;
    }
    return (
      <Grid container spacing={3}>
        {venues.map((venue) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={`venue-${venue.id}`}>
              <Card>
                <CardActionArea onClick={() => navigate(`/venues/${venue.id}`)}>
                  <CardContent>
                    <VenuePicture venue={venue} />
                    <Typography>{venue.name}</Typography>
                    <Typography>
                      {venue.address}, {venue.city}, {venue.state}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  return (
    <div>
      <Header title="Venues" />
      <Container maxWidth="md" sx={{ mb: 3 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClickSearch();
            return false;
          }}>
          <FormControl sx={{ my: 2 }} variant="outlined" fullWidth>
            <InputLabel htmlFor="venue-search-box">
              Search venues by name
            </InputLabel>
            <OutlinedInput
              id="venue-search-box"
              value={search ?? ""}
              type="search"
              onChange={(e) => setSearch(e.target.value)}
              endAdornment={
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={onClickSearch}>
                  <Search />
                </Button>
              }
              label="Search venues by name"
            />
          </FormControl>
        </form>
        {renderVenues()}
      </Container>
    </div>
  );
};
