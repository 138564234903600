import { useEffect, useState } from "react";
import { Band } from "src/API";
import { cognitoGroups } from "src/utils/auth";

export const useIsBandMember = ({
  band,
  bandId,
}: {
  band?: Band;
  bandId?: string;
}): { isMember: boolean; isLoading: boolean } => {
  const [isMember, setIsMember] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getIsMember = async () => {
      setIsLoading(true);
      const groups = await cognitoGroups();
      if (!groups) {
        setIsMember(false);
        return;
      }
      const id = band ? band.id : bandId ? bandId : null;
      if (id) {
        setIsMember(groups.includes(`${id}-members`));
      }
      setIsLoading(false);
    };
    getIsMember();
  }, [band, bandId]);

  return { isMember, isLoading };
};
