import {
  Badge,
  Container,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import BandMemberRequests from "./segments/BandMemberRequests";
import BandShowRequests from "./segments/BandShowRequests";
import FriendshipRequests from "./segments/FriendshipRequests";
import { useEffect, useState } from "react";
import TabPanel from "src/components/TabPanel";
import { useQuery } from "react-query";
import {
  getMyBandRequests,
  getMyBandShowRequests,
  getMyPendingFriendRequests,
} from "./backend";
import { useSelector } from "react-redux";
import { RootState } from "src/stores";
import { BandShow } from "src/API";
import { SentimentDissatisfied } from "@mui/icons-material";

const usePendingFriendshipRequests = (id?: string | null) => {
  return useQuery(
    ["user", id, "pendingFriendshipRequests"],
    async () => {
      if (!id) {
        return null;
      }
      const pendingFriendRequests = await getMyPendingFriendRequests(id);
      return pendingFriendRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useBandShowRequestsForUser = (id?: string | null) => {
  return useQuery(
    ["user", id, "bandShowRequests"],
    async () => {
      if (!id) {
        return null;
      }
      const bandShowRequests = await getMyBandShowRequests(id);
      return bandShowRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useMyBandRequests = (userId?: string | null) => {
  return useQuery(
    ["myBandRequests", userId],
    async () => {
      if (!userId) {
        return null;
      }
      const bandRequests = await getMyBandRequests(userId);
      return bandRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const Notifications = () => {
  const [tabValue, setTabValue] = useState<string>("band-member");
  const { id: userId } = useSelector((state: RootState) => state.user);

  const [pendingBandShows, setPendingBandShows] = useState<BandShow[]>([]);

  const [isTabSet, setIsTabSet] = useState<boolean>(false);

  const {
    data: bandRequests,
    isFetching: isFetchingBandRequests,
    isLoading: isLoadingBandRequests,
    refetch: refetchBandRequests,
  } = useMyBandRequests(userId);

  const {
    data: bandShowRequests,
    isLoading: isLoadingBandShowRequests,
    isFetching: isFetchingShowRequests,
    refetch: refetchBandShowRequests,
  } = useBandShowRequestsForUser(userId);

  const {
    data: friendshipRequests,
    isLoading: isLoadingFriendshipRequests,
    isFetching: isFetchingFriendshipRequests,
    refetch: refetchFriendshipRequests,
  } = usePendingFriendshipRequests(userId);

  useEffect(() => {
    if (!bandShowRequests) {
      return;
    }
    const allPendingBandShows = bandShowRequests.reduce((acc, curr) => {
      const { band } = curr;
      if (!band || !band.hasPendingBandShow) {
        return acc;
      }
      const { pendingBandShows } = band;
      if (!pendingBandShows || !pendingBandShows.items) {
        return acc;
      }
      const pendingShows = pendingBandShows.items.reduce((a, c) => {
        const { show } = c;
        if (!show) {
          return a;
        }
        return [...a, c];
      }, [] as BandShow[]) as BandShow[];
      return [...acc, ...pendingShows];
    }, [] as BandShow[]) as BandShow[];

    setPendingBandShows(allPendingBandShows);
  }, [bandShowRequests]);

  useEffect(() => {
    if (isTabSet) {
      return;
    }
    if (bandRequests && bandRequests.length > 0) {
      setTabValue("band-member");
      setIsTabSet(true);
    } else if (pendingBandShows && pendingBandShows.length > 0) {
      setTabValue("shows");
      setIsTabSet(true);
    } else if (friendshipRequests && friendshipRequests.length > 0) {
      setTabValue("friendship");
      setIsTabSet(true);
    }
  }, [isTabSet, pendingBandShows, bandRequests, friendshipRequests]);

  if (
    isFetchingBandRequests ||
    isFetchingShowRequests ||
    isFetchingFriendshipRequests ||
    isLoadingBandRequests ||
    isLoadingBandShowRequests ||
    isLoadingFriendshipRequests
  ) {
    return (
      <Container>
        <LinearProgress />
      </Container>
    );
  }

  if (
    bandRequests &&
    bandRequests.length === 0 &&
    pendingBandShows &&
    pendingBandShows.length === 0 &&
    friendshipRequests &&
    friendshipRequests.length === 0
  ) {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 3,
        }}>
        <Stack gap={3} direction="row" alignItems="center">
          <Typography variant="h1">No notifications</Typography>

          <SentimentDissatisfied fontSize="large" />
        </Stack>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Tabs
        sx={{ mb: 1 }}
        centered
        value={tabValue}
        onChange={(_, newTab: string) => setTabValue(newTab)}>
        <Tab
          disabled={!bandRequests || bandRequests.length === 0}
          label={
            <Badge
              color="error"
              badgeContent={bandRequests?.length}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}>
              Band Membership
            </Badge>
          }
          value="band-member"
        />
        <Tab
          disabled={!pendingBandShows || pendingBandShows.length === 0}
          label={
            <Badge
              color="error"
              badgeContent={pendingBandShows.length}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}>
              Shows
            </Badge>
          }
          value="shows"
        />
        <Tab
          disabled={!friendshipRequests || friendshipRequests.length === 0}
          label={
            <Badge
              color="error"
              badgeContent={friendshipRequests?.length}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}>
              Friendship
            </Badge>
          }
          value="friendship"
        />
      </Tabs>
      {bandRequests && bandRequests.length > 0 && (
        <TabPanel value="band-member" index={tabValue}>
          <BandMemberRequests
            bandRequests={bandRequests}
            isFetching={isFetchingBandRequests}
            refetch={refetchBandRequests}
          />
        </TabPanel>
      )}
      {pendingBandShows && pendingBandShows.length > 0 && (
        <TabPanel value="shows" index={tabValue}>
          <BandShowRequests
            pendingBandShows={pendingBandShows}
            isFetching={isFetchingShowRequests}
            refetch={refetchBandShowRequests}
          />
        </TabPanel>
      )}
      {friendshipRequests && friendshipRequests.length > 0 && (
        <TabPanel value="friendship" index={tabValue}>
          <FriendshipRequests
            friendshipRequests={friendshipRequests}
            isFetching={isFetchingFriendshipRequests}
            refetch={refetchFriendshipRequests}
          />
        </TabPanel>
      )}
    </Container>
  );
};
